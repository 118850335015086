<template>
  <div class="mx-3">
    <v-row>
      <v-col cols="4">
        <v-select
          :items="memberTags"
          v-model="selectedMemberTags"
          item-text="tag"
          item-value="id"
          label="Member Tags"
          chips
          multiple
          deletable-chips
          small-chips
        />
        <v-select
          :items="companyTags"
          v-model="selectedCompanyTags"
          item-text="tag"
          item-value="id"
          label="Company Tags"
          chips
          multiple
          deletable-chips
          small-chips
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  data: function () {
    return {
      isProcessing: false,
    };
  },
  dictionary: {
    custom: {},
  },
  computed: {
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    loading() {
      return this.$store.getters.loading;
    },
    created() {
      return this.currentOffer.created;
    },
    selectedMemberTags: {
      get() {
        return this.currentOffer.memberTags;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", { memberTags: value });
      },
    },
    selectedCompanyTags: {
      get() {
        return this.currentOffer.companyTags;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", { companyTags: value });
      },
    },
  },
  methods: {},
};
</script>

<style scoped></style>
