<template>
  <v-app style="background: #fffff">
    <!-- Nav Drawer -->
    <nav-drawer v-if="navmenu"></nav-drawer>

    <!-- Toolbar -->
    <div v-if="toolbar">
      <v-app-bar
        dark
        app
        flat
        prominent
        dense
        clipped-right
        clipped-left
        class="gradient-background"
      >
        <template v-slot:img="{ props }">
          <v-img v-bind="props"> </v-img>
        </template>

        <img
          :src="require('@/assets/incentable-logo.png')"
          height="70px"
          contain
          class="mt-3"
        />

        <v-spacer></v-spacer>

        <v-btn text height="68" class="mt-3" to="/">
          <v-row no-gutters justify="center" align="end">
            <v-col>
              <v-row no-gutters justify="center" align="center">
                <v-icon medium class="mr-1 mb-2" color="white">apps</v-icon>
              </v-row>
              <v-row no-gutters justify="center" align="center">
                <span
                  v-if="isIncentableAdmin && newMessageCount > 0"
                  class="hidden-sm-and-down"
                  >Programs</span
                >
                <span v-else class="hidden-sm-and-down">Programs</span>
              </v-row>
            </v-col>
          </v-row>
        </v-btn>

        <v-btn
          v-if="isIncentableAdmin"
          text
          height="68"
          class="mt-3"
          to="/academy"
          target="_blank"
        >
          <v-row no-gutters justify="center" align="end">
            <v-col>
              <v-row no-gutters justify="center" align="center">
                <v-icon medium class="mr-1 mb-2" color="white">school</v-icon>
              </v-row>
              <v-row no-gutters justify="center" align="center">
                <span
                  v-if="isIncentableAdmin && newMessageCount > 0"
                  class="hidden-sm-and-down"
                  >Academy</span
                >
                <span v-else class="hidden-sm-and-down">Academy</span>
              </v-row>
            </v-col>
          </v-row>
        </v-btn>

        <v-btn text height="68" class="mt-3" to="/support">
          <v-row no-gutters justify="center" align="end">
            <v-col>
              <v-row no-gutters justify="center" align="center">
                <v-icon medium class="mr-1 mb-2" color="white">support</v-icon>
              </v-row>
              <v-row no-gutters justify="center" align="center">
                <span class="hidden-sm-and-down">support</span>
              </v-row>
            </v-col>
          </v-row>
        </v-btn>

        <v-menu close-on-content-click offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" text height="68" class="mt-3">
              <v-row no-gutters justify="center" align="end">
                <v-col>
                  <v-row no-gutters justify="center" align="center">
                    <v-icon medium class="mr-1 mb-2" color="white"
                      >account_circle</v-icon
                    >
                  </v-row>
                  <v-row no-gutters justify="center" align="center">
                    <span
                      v-if="isIncentableAdmin && newMessageCount > 0"
                      class="hidden-sm-and-down"
                      ><v-badge
                        offset-y="5"
                        color="secondary"
                        :content="newMessageCount"
                        >{{ name }}</v-badge
                      ></span
                    >
                    <span v-else class="hidden-sm-and-down">{{ name }}</span>
                  </v-row>
                </v-col>
              </v-row>
            </v-btn>
          </template>

          <v-list v-if="isIncentableAdmin" dense>
            <v-subheader class="incentable-form-heading primary--text ml-3"
              >Incentable Admin</v-subheader
            >

            <v-list-item link to="/admins">
              <v-list-item-icon>
                <v-icon color="#e91e63">account_circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="incentable">Admins</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/messages">
              <v-list-item-icon>
                <v-badge
                  v-if="newMessageCount > 0"
                  color="secondary"
                  :content="newMessageCount"
                  ><v-icon color="#e91e63">email</v-icon></v-badge
                >
                <v-icon v-else color="#e91e63">email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="incentable"
                  >Support Mail</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/subscriptions">
              <v-list-item-icon>
                <v-icon color="#e91e63">paid</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="incentable"
                  >Subscriptions</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/sites">
              <v-list-item-icon>
                <v-icon color="#e91e63">dns</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="incentable"
                  >Domains</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/log">
              <v-list-item-icon>
                <v-icon color="#e91e63">assignment</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="incentable">Log</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <!-- ************ THIS MENU ITME NEEDS TO BE MOVED TO USERS WHEN ORGANISATION FEATURE GOES LIVE ************* -->
            <v-list-item link to="/organisations">
              <v-list-item-icon>
                <v-icon color="#e91e63">location_city</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="incentable"
                  >Organisation</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list>

          <!-- *********** User Admin Menu Items ****************** -->
          <v-list dense>
            <v-subheader class="incentable-form-heading ml-3"
              >My Account</v-subheader
            >
            <v-list-item link to="/profile">
              <v-list-item-icon>
                <v-icon>person</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/billing">
              <v-list-item-icon>
                <v-icon>receipt_long</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Billing</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click="onLogout">
              <v-list-item-icon>
                <v-icon>exit_to_app</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sign Out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </div>
    <!-- End Toolbar -->

    <v-app-bar
      v-if="navmenu"
      dark
      app
      flat
      dense
      clipped-right
      clipped-left
      color="grey lighten-4"
      class="name-bar-margin"
    >
      <v-row no-gutters align="center">
        <v-col class="program-name">
          <v-row no-gutters align="center">
            {{ currentProgram.title || "" }}
            <breadcrumbs></breadcrumbs>
          </v-row>
        </v-col>
        <v-col>
          <StatusBar class="float-right"></StatusBar>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>

    <!-- Alert -->
    <app-snackbar></app-snackbar>
    <app-snackbar-light></app-snackbar-light>

    <template v-if="footer">
      <v-footer padless app inset color="transparent" elevation="0">
        <v-spacer></v-spacer>
        <live-button
          class="mb-4 mr-4"
          v-if="navmenu && programId"
        ></live-button>
      </v-footer>
    </template>
  </v-app>
</template>

<script>
import StatusBar from "./components/Dashboard/Components/StatusBar/StatusBar.vue";
import Breadcrumbs from "./components/Shared/Breadcrumbs.vue";
export default {
  data() {
    return {};
  },
  components: { StatusBar, Breadcrumbs },
  created() {
    this.$store.dispatch("loadSystemAdmins");
    this.$store.dispatch("loadNewMessageCount");
  },
  computed: {
    howTo() {
      return this.$store.state.howTo;
    },
    newMessageCount() {
      return this.$store.state.message.newMessageCount;
    },
    navTitle() {
      return this.$store.state.navTitle;
    },
    navmenu() {
      return this.$store.state.navmenu;
    },
    drawer() {
      return this.$store.state.drawer;
    },
    toolbar() {
      return this.$store.state.toolbar;
    },
    footer() {
      return this.$store.state.footer;
    },
    programId() {
      return this.$store.state.program.programId;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    subdomain() {
      return this.$store.getters.subdomain;
    },
    loading() {
      return this.$store.getters.loading;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    email() {
      return this.$store.getters.email;
    },
    name() {
      if (this.systemAdmins.length > 0) {
        return (
          this.systemAdmins.find((admin) => admin.email === this.email).name ||
          ""
        );
      }
      return "";
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    currentUserRole() {
      return this.$store.getters.currentUserRole;
    },
    systemAdmins() {
      return this.$store.state.systemadmin.systemAdmins;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
  },
  methods: {
    log(action) {
      this.$store.dispatch("addLog", { action: action });
    },
    toggleDrawer() {
      const val = this.drawer;
      if (val == false) {
        this.$store.dispatch("setDrawer", true);
        this.$store.dispatch("setNavTitle", false);
      } else {
        this.$store.dispatch("setDrawer", false);
        this.$store.dispatch("setNavTitle", true);
      }
    },
    toggleHowTo() {
      const val = this.$store.state.howTo;
      if (val == false) {
        this.$store.dispatch("setHowTo", true);
      } else {
        this.$store.dispatch("setHowTo", false);
      }
    },
    onLogout() {
      this.$store.dispatch("logout");
      this.$router.push("/signin");
    },
    handleCloseHowTo() {
      this.$store.dispatch("setHowTo", false);
    },
    getHelp() {
      window.open(
        "https://incentablehelp.zendesk.com/hc/en-us",
        "_blank",
        "noreferrer"
      );
    },
    openAcademy() {
      let resolvedRoute = this.$router.resolve({
        name: "Academy",
      });
      window.open(resolvedRoute.href, "_blank");
    },
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch("addLog", {
        action: "Browse",
        to: to.path,
        from: from.path,
      });
      this.$store.dispatch("setBreadCrumbDetail", null);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Caveat:wght@400;500;600;700&family=Cinzel:wght@400;500;600;700;800;900&family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.lottie {
  position: absolute;
  z-index: 1000;
}
.lottie-full-width {
  position: absolute;
  z-index: 1000;
  width: 100%;
}
.lottie-full-height {
  position: absolute;
  z-index: 1000;
  height: 100%;
}
.incentable {
  color: #e91e63;
}
.gradient-background {
  background: linear-gradient(
    25deg,
    rgba(55, 5, 155, 1) 0%,
    rgba(233, 30, 99, 1) 100%
  );
}
.gradient-card-background {
  background: linear-gradient(
    195deg,
    rgba(55, 5, 155, 1) 0%,
    rgba(233, 30, 99, 1) 100%
  );
}
.gradient-card-background-dark {
  background: linear-gradient(340deg, rgb(42, 5, 116) 0%, rgb(69, 7, 194) 100%);
}
.manage-button {
  position: absolute !important;
  margin-top: 80px !important;
}
.hero {
  background: url("https://firebasestorage.googleapis.com/v0/b/incentable-app-37d9c.appspot.com/o/system%2Fpage-background.png?alt=media&token=e39642f4-b409-4eb0-99d6-2b6b4ce8d482");
  background-size: cover;
  height: 100vh;
}
.page-bar {
  margin-top: 96px !important;
}
.size-label {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: normal;
  font-family: "Figtree", sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  margin-top: 3px;
}
.page {
  padding: 0px 0px 0px 0px !important;
}
.program-page {
  padding: 20px 00px 0px 0px !important;
}
.el {
  box-shadow: none !important;
}
.theme--light.v-tabs > .v-tabs-bar {
  background-color: transparent !important;
}
.theme--light.v-tabs-items {
  background-color: transparent !important;
}
.v-application {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #505050 !important;
}
.metric {
  font-size: 36px !important;
  font-weight: 500 !important;
}
.switch {
  margin-top: 22px !important;
}
.switch-label {
  margin-top: 2px !important;
  font-family: "Figtree", sans-serif !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.0892857143em;
  text-indent: 0.0892857143em;
  white-space: nowrap;
}
.text-icon {
  font-family: "Figtree", sans-serif !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  color: #505050 !important;
}
.vue-dropzone {
  font-weight: 500 !important;
  font-size: 12px !important;
  font-family: "Lato", sans-serif !important;
  color: #ffffff !important;
}
.icon {
  color: #505050 !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #505050 !important;
}
.theme-menu {
  font-family: "Figtree", sans-serif !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  color: #505050 !important;
  line-height: 1.2;
}
.award {
  font-weight: 700 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #505050 !important;
}
.link {
  text-decoration: none !important;
}
.back-link {
  font-weight: 500 !important;
  font-size: 11px !important;
  font-family: "Figtree", sans-serif !important;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.filename {
  font-family: "Figtree", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  line-height: 1em;
}
.domain-name {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #acacac !important;
}
.filename {
  font-size: 14px;
  font-weight: 700;
}
.card-title {
  font-family: "Figtree", sans-serif !important;
  font-size: 15pt;
  font-weight: 400;
  letter-spacing: 1px;
  color: #505050 !important;
}
.page-title {
  font-family: "Montserrat", sans-serif !important;
  font-size: 11pt;
  font-weight: 700;
  color: #505050 !important;
}
.toolbar-title {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: white !important;
}
.toolbar-card-heading {
  font-family: "Figtree", sans-serif !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  color: white !important;
}
.toolbar-card-subheading {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px;
  font-weight: 200 !important;
  color: white !important;
}
.v-label {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
.v-list-item__title {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.v-btn {
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 600 !important;
  margin: 4px;
}
.tab {
  color: #505050 !important;
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 700 !important;
}
.v-tab--active {
  color: #e91e63 !important;
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 700 !important;
}
.incentable-heading {
  font-family: "Figtree", sans-serif !important;
  font-size: 30px;
  font-weight: 400 !important;
}
.page-heading {
  font-family: "Montserrat", sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #505050 !important;
}
.incentable-page-subheading {
  font-family: "Figtree", sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #505050 !important;
}
.map-header {
  font-weight: 500 !important;
  background-color: #e0e0e0 !important;
}
.map-col-1 {
  background-color: #e0e0e0 !important;
  padding: 4px;
}
.map-cell {
  padding: 4px;
  margin: 0px 1px 1px 0px;
  min-height: 30px;
}
.import-form-header {
  font-family: "Figtree", sans-serif !important;
  font-size: 15px;
  font-weight: 700 !important;
  padding: 10px;
  margin: 0px 1px 1px 0px;
  min-height: 40px;
  background-color: #e0e0e0 !important;
}
.import-form {
  font-family: "Figtree", sans-serif !important;
  font-size: 15px;
  font-weight: 500 !important;
  padding: 10px;
  margin: 0px 1px 1px 0px;
  min-height: 42px;
}
.import-select {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin: -4px 0px 0px 0px !important;
  padding: -10px 0px 0px 0px !important;
  width: 210px;
  height: 38px !important;
}
.dash-card-title {
  font-family: "Figtree", sans-serif !important;
  font-size: 15px;
  font-weight: 500 !important;
  margin-left: 0px !important;
  margin-bottom: 4px !important;
  color: #505050 !important;
}
.file-title {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px;
  font-weight: 600 !important;
  margin-left: 2px !important;
  margin-bottom: 4px !important;
  color: #505050 !important;
}
.dash-card {
  font-family: "Figtree", sans-serif !important;
  font-size: 11px;
  font-weight: 400 !important;
  line-height: 1.2;
  color: #505050 !important;
}
.incentable-subheading {
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  color: #505050 !important;
}
.incentable-form-bold {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px;
  font-weight: 600 !important;
  color: #505050 !important;
}
.incentable-formula-heading {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #505050 !important;
}
.incentable-form-heading {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #505050 !important;
}
.incentable-form-subheading {
  font-family: "Figtree", sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #505050 !important;
  margin-top: -8px;
  margin-bottom: 6px;
  line-height: 2.5;
}
.incentable-alert {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.close-icon {
  position: absolute;
  margin: -7px -17px;
}
.incentable-leaderboard-table-title-row {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  padding: 12px 22px !important;
  color: #505050 !important;
  background-color: #e0e0e0 !important;
}
.table-title-row {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  padding: 18px 22px !important;
  color: #505050 !important;
  background-color: #e0e0e0 !important;
}
.table-cell {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #505050 !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  background-color: #f1f1f1 !important;
  padding: 10px 20px !important;
  line-height: 34px;
}
.incentable-csv-table-title-row {
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  padding: 7px 14px 7px 14px !important;
  border: 1px solid #e0e0e0 !important;
  background-color: #eeeeee !important;
}
.incentable-csv-table-number-column {
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  padding: 7px 0px 7px 0px !important;
  border: 1px solid #e0e0e0 !important;
  background-color: #eeeeee !important;
  text-align: center !important;
}
.incentable-csv-table-cell {
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 7px 14px 7px 14px !important;
  font-family: "Figtree", sans-serif !important;
  border: 1px solid #e0e0e0 !important;
}
.table-total-row {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 10px !important;
  color: #505050 !important;
  background-color: #e0e0e0 !important;
}
.movable {
  cursor: move;
}
.table-first-col {
  margin-right: 1px !important;
  padding: 5px 20px !important;
  color: #505050 !important;
  background-color: #e0e0e0 !important;
}
.table-icon {
  margin-right: 1px !important;
  margin-bottom: 2px !important;
  background-color: #1d1d1d !important;
  padding-top: 3px;
  padding-left: 3px;
  padding-bottom: 2px;
  line-height: 42px;
}
.table-icon-cell {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  background-color: #ffffff !important;
  padding-top: 1px;
  line-height: 34px;
  min-width: 45px;
}
.incentable-points-table-title-row {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #505050 !important;
}
.cell-trim {
  padding-left: -6px;
  padding-right: -6px;
}
.operator {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  background-color: #e0e0e0;
  color: #505050 !important;
}
.andor {
  font-family: "Figtree", sans-serif !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  background-color: #e0e0e0;
  color: #505050 !important;
}
.rotate {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}
.table-title {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #505050 !important;
  background-color: #e0e0e0 !important;
}
.table-subtitle {
  font-family: "Figtree", sans-serif !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #505050 !important;
}
.incentable-databucket-table-title {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #505050 !important;
}
.incentable-databucket-table-subtitle {
  font-family: "Figtree", sans-serif !important;
  font-size: 10px !important;
  font-weight: 500 !important;
  color: #505050 !important;
}
.table-right {
  text-align: right;
  padding-right: 8px;
}
.table-left {
  text-align: left;
  padding-left: 8px;
}
.incentable-card-title {
  font-family: "Montserrat", sans-serif !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  margin-left: 8px;
  margin-right: 10px;
  margin-top: 0px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #505050 !important;
}
.incentable-program-card-title {
  font-family: "Montserrat", sans-serif !important;
  font-size: 26px !important;
  font-weight: 600 !important;
  margin-left: 8px;
  margin-right: 10px;
  margin-top: 0px;
  line-height: 1.1em;
  cursor: pointer;
  color: #ffffff !important;
}
.incentable-bullet-list {
  font-family: "Figtree", sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-left: 8px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: #ffffff !important;
}
.incentable-create-card-subtitle {
  font-family: "Figtree", sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-left: 8px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: #505050 !important;
}
.incentable-card-subtitle {
  font-family: "Figtree", sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-left: 8px !important;
  margin-top: -15px !important;
  margin-bottom: 0px !important;
  color: #505050 !important;
}
.incentable-card-action-text {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-left: 8px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  color: #505050 !important;
}
.incentable-dash-card-subtitle {
  font-family: "Figtree", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #505050 !important;
}
.incentable-member-name {
  font-family: "Quicksand", sans-serif !important;
  font-size: 20px !important;
  font-weight: 400 !important;
  color: #505050 !important;
}
.incentable-member-points {
  font-family: "Figtree", sans-serif !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #505050 !important;
}
.tab-table-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 24px !important;
}
.menu-heading-label {
  font-family: "Figtree", sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #acacac !important;
  margin-left: 14px;
}
.table-subtext {
  font-family: "Figtree", sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #505050 !important;
  margin-top: -2px !important;
}
.v-list-item .v-list-item__subtitle,
.v-list-item .v-list-item__title {
  line-height: 1 !important;
}
.v-input {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #505050 !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: 600 !important;
  font-size: 12px !important;
  font-family: "Montserrat", sans-serif !important;
  color: #505050 !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #505050 !important;
}
.table-link {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #e91e63 !important;
}
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #505050 !important;
}
.caption {
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "Figtree", sans-serif !important;
  color: #505050 !important;
}
.grid-block {
  border: 1px dashed grey !important;
  background-color: transparent !important;
}
.grid-outer {
  border: 1px dotted grey !important;
  background-color: transparent !important;
}
.grid-inner {
  border: 1px dotted white !important;
  background-color: transparent !important;
}
.grid-off {
  border: 1px dotted transparent !important;
  background-color: transparent !important;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.link {
  cursor: pointer;
}
.program-name {
  font-family: "Montserrat", sans-serif !important;
  font-size: 15px;
  font-weight: 700 !important;
  color: #505050 !important;
  line-height: 1;
}
.name-bar-margin {
  margin-top: 96px !important;
}
.rounded-card {
  border-radius: 17px !important;
}
</style>
