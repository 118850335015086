<template>

  <div class="mt-8 mr-8 mb-8">
    <v-row
      no-gutters
      align="center"
      >
      <v-col lg="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
          clearable
          @click:clear="resetSearch()"
        ></v-text-field> 
      </v-col>
      
      <v-col
        lg="3"
        v-if="options.filter"
        >
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter By Status"
          prepend-inner-icon="filter_list"
          class="mr-4"
          multiple
          :items="orderItemStatuses"
          v-model="filteredStatus"
          clearable
          @click:clear="resetStatusFilter()"
        ></v-select> 
      </v-col>
      <v-col
        lg="3"
        v-if="options.filter"
        >
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter By Supplier"
          prepend-inner-icon="filter_list"
          class="mr-4"
          multiple
          :items="rewardSuppliers"
          v-model="filteredSupplier"
          clearable
          @click:clear="resetSupplierFilter()"
          item-value="id"
          item-text="title"
        ></v-select> 
      </v-col>
      
      <v-col>
        <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
        
        <v-btn 
          v-if="options.download"
          class="primary--text"
          text
          :disabled="!updateStatusDisabled || demo"
          :href="ordersCsv"
          :download="getFilename()"
        >Download</v-btn> 
        
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
      </v-col>
      
      <v-col sm="auto">

      </v-col>
    </v-row>

    <v-row align="start" no-gutters class="mt-3">
      <v-col lg="3">
        <v-select
          v-model="selectedStatus"
          :items="orderItemStatuses"
          label="Update Status To..."
          :disabled="updateStatusDisabled"
          solo-inverted
          dense
          flat
          class="mr-4"
        ></v-select>
      </v-col>
      <v-col cols="auto" class="button-adjust ml-n1">
        <v-btn
          v-if="selectedStatusSaveButton || processingStatusChange"
          @click="handleStatusChange()"
          color="primary"
          :class="orgTheme.buttonTextColor"
          :loading="processingStatusChange"
          :disabled="updateStatusDisabled"
          elevation="0"
          >apply
        </v-btn>
      </v-col>
    </v-row>
        
    <v-divider class="mt-n3"/>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredOrders"
      item-key="itemId"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
      show-select
    >
      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status" class="pr-3"></status-icon>
        {{ item.status }}
      </template>
      
      <template v-slot:[`item.each`]="{ item }">
        {{ item.each | number }}
      </template>
      
      <template v-slot:[`item.memberId`]="{ item }">
        {{ getMember(item.memberId).firstname + ' ' + getMember(item.memberId).lastname }}
      </template>
      
      <template v-slot:[`item.rewardSupplier`]="{ item }">
        {{ getSupplier(item.rewardSupplier).title }}
      </template>
      
      <template v-slot:[`item.subtotal`]="{ item }">
        {{ item.subtotal | number }}
      </template>
      
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | timestamp | date }}
      </template>
      
      <template v-slot:[`item.title`]="{ item }">
        <span  class="table-link link" @click="handleOpenRewardDetail(item)">{{ item.title | truncate(45) }}</span>
      </template>
      
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          left
          close-on-content-click
        >
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
              >
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list dense>                
              
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor')"
                class="mb-2"
                >
                  <v-list-item-icon>
                    <v-icon size="20">fa-solid fa-gift</v-icon>
                    
                  </v-list-item-icon>
                  <v-list-item-title
                    @click="handleOpenRewardDetail(item)"
                    class="mt-1 ml-n4"
                    >
                    View Reward
                  </v-list-item-title>
              </v-list-item>
            </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <orders-new-or-edit
      :orderId="selectedOrderId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <RewardDetail
      v-if="selectedReward"
      :open="dialogRewardOpen"
      :reward="selectedReward"
      @onClose="handleCloseRewardDetail"
    ></RewardDetail>

    <ConfirmStatusChange
      :open="confirmStatusChangeDialogOpen"
      :selectedStatus="selectedStatus"
      :items="selected"
      @onClose="handleCloseConfirmStatusChangeDialog"
      @onConfirm="confirmStatusChange"
    ></ConfirmStatusChange>

  </div>

</template>

<script>
import { ORDER_STATUS } from '@/constants/order'
import { ORDER_ITEM_STATUS } from '@/constants/order'
import OrdersNewOrEdit from './OrdersNewOrEdit.vue'
import RewardDetail from './RewardDetail.vue'
import ConfirmStatusChange from './ConfirmStatusChange.vue'
export default {
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    OrdersNewOrEdit,
    RewardDetail,
    ConfirmStatusChange
  },
  data: () => ({
    
    // Component settings
    tableTitle: 'Orders',
    tableSubtitle: 'Add orders',
    tableIcon: 'storefront',
    search: '',
    title: '',
    createdBy: '',
    selectedOrderId: '',
    dialogNewOrEditOpen: false,
    selectedReward: '',
    dialogDeleteOpen: false,
    dialogRewardOpen: false,
    hasChanged: false,
    selected: [],
    selectedStatus: '',
    selectedSupplier: '',
    confirmStatusChangeDialogOpen: false,
    statusList: ORDER_STATUS,
    orderItemStatuses: ORDER_ITEM_STATUS,
    filteredStatus: [],
    filteredSupplier: [],
    itemDeleting: '',
    // Table settings
    options: {
      sortBy: ['created'],
      sortDesc: [true],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: false,
      download: true,
      more: false,
    },
    
    // Table columns
    headers: [
      { text: 'Status', value: 'status', align: 'left' },
      { text: 'Item', value: 'title', align: 'left'},
      { text: 'Member', value: 'memberId', align: 'left'},
      { text: 'Created', value: 'created', align: 'left'},
      { text: 'Supplier', value: 'rewardSupplier', align: 'left'},
      { text: 'SKU', value: 'sku', align: 'left'},
      { text: 'Each', value: 'each', align: 'right', sortable: false},
      { text: 'Quantity', value: 'quantity', align: 'right', sortable: false},
      { text: 'Subtotal', value: 'subtotal', align: 'right', sortable: false},
      { text: 'Actions', value: 'actions', align: 'center', sortable: false},
    ],
  }),
  
  created () {
    this.$store.dispatch('setProcessingStatusChange', false)
  },

  computed: {
    // Functions specific to this table //
    orders () {
      return this.$store.state.order.orders
    },
    rewardSuppliers () {
      return this.$store.state.rewardsupplier.rewardSuppliers
    },
    ordersByItem () {
      const orders = this.orders.flatMap(({
        name,
        email,
        address,
        suburb,
        state,
        postcode,
        country,
        items 
      }) => {
        return items.map(obj => ({ 
          name,
        email,
        address,
        suburb,
        state,
        postcode,
        country,
          ...obj
        }))
      })
      return orders
    },
    filteredOrders() {
      const filtered = this.search === '' ? this.ordersByItem : this.ordersByItem.filter(el => el.title.toLowerCase().includes(this.search.toLowerCase()))
      const filteredByStatus = this.filteredStatus.length
        ? filtered.filter(m => this.filteredStatus.includes(m.status))
        : filtered
      const result = this.filteredSupplier.length
        ? filteredByStatus.filter(m => this.filteredSupplier.includes(m.rewardSupplier))
        : filteredByStatus
      return result
    },
    loadingTable () {
      return this.$store.getters.loadingTable
    },
    loading () {
      return this.$store.getters.loading
    },
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
    snackbar () {
      return this.$store.getters.snackbar
    },
    processingStatusChange () {
      return this.$store.getters.processingStatusChange
    },
    members () {
      return this.$store.state.member.members
    },
    updateStatusDisabled () {
      if(this.selected.length > 0) {
        return false
      }
      return true
    },
    selectedStatusSaveButton () {
      if(this.selectedStatus) {
        return true
      }
      return false
    },
    ordersCsv() {
      const dataHeader = 'data:text/csv;charset=utf-8,';
      const csvHeader = 'Status,Product,Email,Name,Address,Suburb,State,Postcode,Country,Created,Updated,Supplier,Sku,Price,Points Each,Quantity,Points Subtotal';
      const csvBody = this.filteredOrders.map(order => {
        const {
          status,
          title,
          email,
          name,
          address,
          suburb,
          state,
          postcode,
          country,
          created,
          updated,
          rewardSupplier,
          sku,
          price,
          each,
          quantity,
          subtotal
        } = order;
        var createdFormatted = this.$options.filters.timestamp(created)
        var updatedFormatted = this.$options.filters.timestamp(updated)
        var supplier = this.getSupplier(rewardSupplier).title || ''
        return `${status},${title},${email},${name},${address},${suburb},${state},${postcode},${country},${createdFormatted},${updatedFormatted},${supplier},${sku},${price},${each},${quantity},${subtotal}`;
      }).join('\r\n');

      return `${dataHeader}${csvHeader}\r\n${csvBody}`;
    },
  },
  methods: {
    getStatusIcon (item) {
      if (item === 'Active') {
        return 'check_circle'
      } else if (item === 'Inactive') {
        return 'not_interested'
      }
    },
    getFilename() {
      const program = this.$store.state.program.currentProgram;
      const date = new Date()
      const formattedDate = date.toLocaleDateString(['en-US'], {month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})
      return program.title + ' Reward Orders ' + formattedDate + '.csv'
    },
    getMember(memberId) {
      return this.members.find((el) => el.id === memberId) || ''
    },
    getSupplier(id) {
      return this.rewardSuppliers.find((el) => el.id === id) || ''
    },
    orderItemCount(order) {
      const arr = order.filter(el => el.status !== 'Cancelled')
      const len = arr.length
      return len
    },
    resetSearch() {
      this.search = ''
    },
    resetStatusFilter() {
      this.filteredStatus = []
    },
    resetSupplierFilter() {
      this.filteredSupplier = []
    },
    handleNew() {
      // Open New form
      this.selectedOrderId = '';
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedOrderId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedOrderId = '';
      this.dialogNewOrEditOpen = false;
    },
    handleOpenRewardDetail(item) {
      this.selectedReward = item
      this.dialogRewardOpen = true;
    },
    handleCloseRewardDetail() {
      this.selectedReward = '';
      this.dialogRewardOpen = false;
    },
    handleStatusChange() {
      this.createdBy = 
      this.confirmStatusChangeDialogOpen = true
    },
    handleCloseConfirmStatusChangeDialog() {
      this.confirmStatusChangeDialogOpen = false
    },
    confirmStatusChange() {
      this.confirmStatusChangeDialogOpen = false
      this.$store.dispatch('setProcessingStatusChange', true)
      const newStatus = this.selectedStatus
      const selected = this.selected
      const uniqueOrderIds = [...new Set(selected.map(item => item.orderId))]

      console.log(uniqueOrderIds)

      uniqueOrderIds.forEach(id => {
        const itemsInOrder = selected.filter(el => el.orderId === id)
        var memberId = itemsInOrder[0].memberId
        const changed = itemsInOrder.map(el => {
          return {
            ...el,
            lastStatus: el.status, 
            status: newStatus,
          }
        })
        const updates = {
          id: id,
          memberId: memberId,
          changedArr: changed
        }
        this.$store.dispatch('updateSelectedStatus', updates)
      })

      this.selectedStatus= false
      this.selected= []
    }
  }
}
</script>

<style>
.v-avatar.av-outline {
  outline: 3px solid white;
}
.button-adjust {
  margin-top: -3px
}
</style>