var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loadingCards)?_c('v-row',{staticClass:"mt-3 mb-16",attrs:{"no-gutters":""}},[(_vm.status === 'Active')?_c('v-col',{staticClass:"pr-6 pb-3 pt-3",attrs:{"cols":"12","xl":"4","sm":"6"}},[_c('v-card',{staticClass:"el rounded-card gradient-card-background",staticStyle:{"height":"224px"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-btn',{staticClass:"manage-button white--text mr-6",attrs:{"elevation":"0","fab":"","color":"primary"},on:{"click":function($event){return _vm.handleAdd()}}},[_c('v-icon',{attrs:{"color":_vm.orgTheme.buttonIconColor}},[_vm._v("add")])],1)],1),_c('v-card-title',[_c('div',{staticClass:"incentable-program-card-title",on:{"click":function($event){return _vm.handleAdd()}}},[_vm._v(" Create a Leaderboard ")])]),_c('v-card-subtitle',{staticClass:"incentable-card-subtitle white--text"},[_vm._v("Rank members or companies based on Data Bucket data")])],1)],1):_vm._e(),(
        _vm.leaderboardsByStatus.length < 1 &&
        !_vm.loadingCards &&
        _vm.status !== 'Active'
      )?_c('v-col',[_c('v-row',{staticClass:"mt-16 page-heading",attrs:{"justify":"center"}},[_c('v-icon',{attrs:{"size":"60","color":"primary"}},[_vm._v("inventory")])],1),_c('v-row',{staticClass:"mt-8 incentable-page-subheading primary--text",attrs:{"justify":"center"}},[_vm._v(" None with the status of "),_c('strong',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.status))])])],1):_vm._e(),_vm._l((_vm.leaderboardsByStatus),function(leaderboard,id){return _c('v-col',{key:id,staticClass:"pr-6 pb-3 pt-3",attrs:{"cols":"12","xl":"4","sm":"6"}},[_c('v-card',{staticClass:"el rounded-card gradient-card-background",staticStyle:{"height":"224px"}},[_c('v-row',{attrs:{"no-gutters":"","justify":"end"}},[_c('v-btn',{staticClass:"manage-button white--text mr-6",attrs:{"elevation":"0","fab":"","color":"primary"},on:{"click":function($event){return _vm.handleManage(leaderboard.id)}}},[_c('v-icon',{attrs:{"color":_vm.orgTheme.buttonIconColor}},[_vm._v("arrow_forward")])],1)],1),_c('v-card-title',[_c('div',{staticClass:"incentable-program-card-title",on:{"click":function($event){return _vm.onLoadLeaderboard(leaderboard)}}},[_vm._v(" "+_vm._s(leaderboard.title)+" ")])]),_c('v-card-subtitle',{staticClass:"incentable-card-subtitle white--text"},[_vm._v(" Updated "+_vm._s(_vm._f("date")(leaderboard.updated))+" ")]),_c('v-row',{staticClass:"pb-3 pl-4 card-actions-left",attrs:{"no-gutters":""}},[(
              leaderboard.mode === 'dynamic' &&
              _vm.getDatabucketName(leaderboard.selectedDatabucket)
            )?_c('v-col',[_c('v-icon',{attrs:{"size":"20","color":"green"}},[_vm._v("fa-solid fa-database")]),_c('span',{staticClass:"databucket-name"},[_vm._v(_vm._s(_vm.getDatabucketName(leaderboard.selectedDatabucket)))])],1):_vm._e(),(
              leaderboard.mode === 'dynamic' &&
              !_vm.getDatabucketName(leaderboard.selectedDatabucket)
            )?_c('v-col',[_c('v-icon',{attrs:{"size":"20","color":"red lighten-2"}},[_vm._v("fa-solid fa-database")]),_c('v-icon',{staticClass:"ml-n5",attrs:{"size":"20","color":"red lighten-2"}},[_vm._v("fa-solid fa-slash")]),_c('span',{staticClass:"databucket-name"},[_vm._v("No Data Bucket linked")])],1):_vm._e(),(
              leaderboard.mode === 'static' && _vm.getCsvFileName(leaderboard.id)
            )?_c('v-col',[_c('v-icon',{attrs:{"size":"20","color":"green"}},[_vm._v("fa-solid fa-file-csv")]),_c('span',{staticClass:"databucket-name"},[_vm._v(_vm._s(_vm.getCsvFileName(leaderboard.id)))])],1):_vm._e(),(
              leaderboard.mode === 'static' && !_vm.getCsvFileName(leaderboard.id)
            )?_c('v-col',[_c('v-icon',{attrs:{"size":"20","color":"red lighten-2"}},[_vm._v("warning")]),_c('span',{staticClass:"databucket-name"},[_vm._v("No file attached")])],1):_vm._e()],1),_c('v-row',{staticClass:"pb-2 pr-2 card-actions-right pl-16",attrs:{"no-gutters":"","justify":"end"}},[_c('v-col',[_c('v-chip',{staticClass:"capitalize table-subtitle white--text float-right",attrs:{"small":"","color":"transparent"}},[(leaderboard.status === 'Active')?_c('v-icon',{staticClass:"mr-1 ml-n1",attrs:{"size":"10","color":"green"}},[_vm._v("circle")]):_c('v-icon',{staticClass:"mr-1 ml-n1",attrs:{"size":"10","color":"red lighten-2"}},[_vm._v("circle")]),_vm._v(" "+_vm._s(leaderboard.status)+" ")],1)],1)],1)],1)],1)})],2):_vm._e(),_c('leaderboard-add',{attrs:{"open":_vm.openAddDialog},on:{"onClose":_vm.handleCloseAddDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }