<template>
  <div class="mt-8 mr-8 mb-8">
    <v-row no-gutters class="incentable-form-heading">
      Default Email Banner
    </v-row>
    <v-row no-gutters class="incentable-form-subheading">
      Click image to change
    </v-row>
    <v-row no-gutters>
      <v-card
        flat
        class="rounded-0"
        width="600px">
        <v-progress-linear 
          v-if="bannerUploading"
          v-model="bannerUploadingProgress"
          >
        </v-progress-linear>

        <v-hover>
          <v-card 
            slot-scope="{ hover }"
            flat
            class="mb-5 pt-0 mt-0 rounded-0"
            >
          
          <v-img 
            :src="bannerUrl || defaultBannerUrl"
            :lazy-src="require('@/assets/placeholder.png')"
            >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>
              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  opacity="0.3"
                  absolute
                >
                  <v-btn icon class="primary" fab elevation="0" large @click="openFileBrowser" :disabled="demo">
                    <v-icon>find_replace</v-icon>
                  </v-btn>
                  <v-btn icon class="primary" fab elevation="0" large @click="handleClearBanner" :disabled="demo">
                    <v-icon>close</v-icon>
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-img>

            <div
              v-if="bannerSize"
              class="float-right incentable-form-subheading"
              >File size: {{ bannerSize }} KB</div>
          </v-card>
          </v-hover>
          
          <div>
            <input
              type="file"
              style="display: none"
              ref="bannerFileInput"
              accept="image/*"
              @change="handleFilePicked"
            />
          </div>
      </v-card>
    </v-row>
    <v-row no-gutters>
        <v-alert
          v-model="showAlert"
          color="primary"
          border="left"
          text
          width="600px"
          >
          Opps, that file exceeds the size limit of 1048 KB
        </v-alert>        
    </v-row>
    <v-btn 
      @click="handleSaveBannerSettings"
      color="primary"
      :loading="bannerSettingProcessing"
      :disabled="bannerUploading || demo"
      elevation="0"
      >
      Save
    </v-btn>
  </div>
</template>
<script>
import { storage, timestamp } from '@/firebase'

import { MAX_BANNER_FILE_SIZE, DEFAULT_BANNER_URL } from '@/constants/email'
export default {
  $_veeValidate: {
    validator: 'new'
  },

  mounted() {
    const settings = this.$store.state.program.currentProgram.notificationSettings || {};
    this.replyEmail = settings.replyEmail || '';
    this.bannerUrl = settings.bannerUrl || '';
    this.$validator.localize('en', this.dictionary)
  },

  data: () => ({
    titleIcon: 'panorama',
    sectionTitle: 'Default Email Banner',
    sectionSubtitle: 'The email banner used by default. Email banners are resized automatically to 600px wide and height varies to maintain apsect ratio. The file size limit is 1048 KB',
    dictionary: {
      custom: {
        replyEmail: {
          email: () => 'Reply Email must be an valid email address.',
        },
      }
    },
    replyEmail: '',
    replySettingProcessing: false,
    bannerSettingProcessing: false,
    defaultBannerUrl: DEFAULT_BANNER_URL,
    bannerName: '',
    bannerUrl: '',
    bannerSize: '',
    bannerUploadTask: null,
    bannerUploadingProgress: 0,
    bannerUploading: false,
    showAlert: false,
  }),

  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    programId () {
      return this.$store.getters.programId;
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    orgTheme () {
    return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    },
  },

  methods: {
    openFileBrowser() {
      this.showAlert = false
      this.$refs.bannerFileInput.click();
    },

    handleSaveReplySettings () {
      this.$validator.validateAll().then(result => {
        if (!result) {
          return;
        }

        const payload = {
          replyEmail: this.replyEmail,
        };

        this.replySettingProcessing = true;
        this.$store.dispatch('updateNotificationSettings', payload)
        this.$store.dispatch('setReplyEmail', payload)
          .then(() => this.replySettingProcessing = false)
          .catch(() => this.replySettingProcessing = false)
      });
    },

    handleFilePicked(e) {
      const file = e.target.files[0];

      if (!file || file.size > MAX_BANNER_FILE_SIZE) {
        this.showAlert = true
        return;
      }

      this.bannerName = file.name;

      this.bannerUploadTask = storage.ref(`uploads/${this.programId}/emailBanners/${timestamp}_${this.bannerName}`).put(file);

      this.bannerUploadingProgress = 0;

      this.bannerUploading = true;

    },

    handleClearBanner() {
      this.bannerName = '';
      this.bannerUploadTask = null;
      this.bannerUploadingProgress = 0;
      this.bannerUploading = false;
      this.bannerUrl = '';
    },

    handleSaveBannerSettings() {
      const payload = {
        bannerUrl: this.bannerUrl,
      };

      this.bannerSettingProcessing = true;
      this.$store.dispatch('updateNotificationSettings', payload)
        .then(() => this.bannerSettingProcessing = false)
        .catch(() => this.bannerSettingProcessing = false)
    }
  },
  watch: {
    bannerUploadTask: function () {
      if (!this.bannerUploadTask) {
        return;
      }

      this.bannerUploadTask.on('state_changed', sp => {
        if (sp.totalBytes <= 0) {
          return;
        }
        this.bannerUploadingProgress = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
        this.bannerSize = Math.round(sp.bytesTransferred / 1000)
      },
      () => {
        this.bannerUploading = false;
      },
      () => {
        this.bannerUploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.bannerUrl = downloadURL;
          this.bannerUploading = false;
        });
      });
    }
  }
}

</script>

<style scoped>
img {
  width: 600px;
}
</style>
