<template>
  <div>
    <!-- Card Table-->
    <v-row no-gutters v-if="!loadingCards" class="mt-3 mb-16">
      <v-col
        v-if="status === 'Active'"
        class="pr-6 pb-3 pt-3"
        cols="12"
        xl="4"
        sm="6"
      >
        <v-card
          class="el rounded-card gradient-card-background"
          style="height: 224px"
        >
          <v-row no-gutters justify="end">
            <v-btn
              class="manage-button white--text mr-6"
              elevation="0"
              fab
              color="primary"
              @click="handleAdd()"
            >
              <v-icon :color="orgTheme.buttonIconColor">add</v-icon>
            </v-btn>
          </v-row>

          <v-card-title>
            <div class="incentable-program-card-title" @click="handleAdd()">
              Create a Leaderboard
            </div>
          </v-card-title>
          <v-card-subtitle class="incentable-card-subtitle white--text"
            >Rank members or companies based on Data Bucket
            data</v-card-subtitle
          >
        </v-card>
      </v-col>

      <v-col
        v-if="
          leaderboardsByStatus.length < 1 &&
          !loadingCards &&
          status !== 'Active'
        "
      >
        <v-row class="mt-16 page-heading" justify="center">
          <v-icon size="60" color="primary">inventory</v-icon>
        </v-row>
        <v-row
          class="mt-8 incentable-page-subheading primary--text"
          justify="center"
        >
          None with the status of <strong class="ml-1">{{ status }}</strong>
        </v-row>
      </v-col>
      <v-col
        v-for="(leaderboard, id) in leaderboardsByStatus"
        :key="id"
        class="pr-6 pb-3 pt-3"
        cols="12"
        xl="4"
        sm="6"
      >
        <v-card
          class="el rounded-card gradient-card-background"
          style="height: 224px"
        >
          <v-row no-gutters justify="end">
            <v-btn
              class="manage-button white--text mr-6"
              elevation="0"
              fab
              color="primary"
              @click="handleManage(leaderboard.id)"
            >
              <v-icon :color="orgTheme.buttonIconColor">arrow_forward</v-icon>
            </v-btn>
          </v-row>
          <v-card-title>
            <div
              class="incentable-program-card-title"
              @click="onLoadLeaderboard(leaderboard)"
            >
              {{ leaderboard.title }}
            </div>
          </v-card-title>
          <v-card-subtitle class="incentable-card-subtitle white--text">
            Updated {{ leaderboard.updated | date }}
          </v-card-subtitle>

          <v-row no-gutters class="pb-3 pl-4 card-actions-left">
            <v-col
              v-if="
                leaderboard.mode === 'dynamic' &&
                getDatabucketName(leaderboard.selectedDatabucket)
              "
            >
              <v-icon size="20" color="green">fa-solid fa-database</v-icon>
              <span class="databucket-name">{{
                getDatabucketName(leaderboard.selectedDatabucket)
              }}</span>
            </v-col>
            <v-col
              v-if="
                leaderboard.mode === 'dynamic' &&
                !getDatabucketName(leaderboard.selectedDatabucket)
              "
            >
              <v-icon size="20" color="red lighten-2"
                >fa-solid fa-database</v-icon
              >
              <v-icon size="20" color="red lighten-2" class="ml-n5"
                >fa-solid fa-slash</v-icon
              >
              <span class="databucket-name">No Data Bucket linked</span>
            </v-col>
            <v-col
              v-if="
                leaderboard.mode === 'static' && getCsvFileName(leaderboard.id)
              "
            >
              <v-icon size="20" color="green">fa-solid fa-file-csv</v-icon>
              <span class="databucket-name">{{
                getCsvFileName(leaderboard.id)
              }}</span>
            </v-col>
            <v-col
              v-if="
                leaderboard.mode === 'static' && !getCsvFileName(leaderboard.id)
              "
            >
              <v-icon size="20" color="red lighten-2">warning</v-icon>
              <span class="databucket-name">No file attached</span>
            </v-col>
          </v-row>
          <v-row
            no-gutters
            class="pb-2 pr-2 card-actions-right pl-16"
            justify="end"
          >
            <v-col>
              <v-chip
                class="capitalize table-subtitle white--text float-right"
                small
                color="transparent"
              >
                <v-icon
                  v-if="leaderboard.status === 'Active'"
                  size="10"
                  class="mr-1 ml-n1"
                  color="green"
                  >circle</v-icon
                >
                <v-icon
                  v-else
                  size="10"
                  class="mr-1 ml-n1"
                  color="red lighten-2"
                  >circle</v-icon
                >
                {{ leaderboard.status }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- End Card Table-->

    <leaderboard-add :open="openAddDialog" @onClose="handleCloseAddDialog" />
  </div>
</template>

<script>
import LeaderboardAdd from "./LeaderboardAdd.vue";

export default {
  components: {
    LeaderboardAdd,
  },
  props: ["leaderboards", "status"],
  created() {},
  data: function () {
    return {
      openAddDialog: false,
    };
  },
  computed: {
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    programId() {
      return this.$store.getters.programId;
    },
    leaderboardsByStatus() {
      var array = this.leaderboards.filter(
        (item) => item.status === this.status
      );
      return array;
    },
    loadingCards() {
      return this.$store.getters.loadingCards;
    },
    databucketNameMap() {
      return this.$store.getters.databuckets.reduce((result, item) => {
        return {
          ...result,
          [item.id]: item.title,
        };
      }, {});
    },
    csvFileNameMap() {
      return this.$store.getters.leaderboardsRaw.reduce((result, item) => {
        return {
          ...result,
          [item.id]: item.csvName,
        };
      }, {});
    },
  },
  methods: {
    getDatabucketName(databucketId) {
      return this.databucketNameMap[databucketId];
    },
    getCsvFileName(databucketId) {
      return this.csvFileNameMap[databucketId];
    },
    handleAdd() {
      this.openAddDialog = true;
    },
    handleCloseAddDialog() {
      this.openAddDialog = false;
    },
    handleManage(leaderboardId) {
      this.$router.push(
        `/leaderboards/${this.programId}/leaderboard/${leaderboardId}`
      );
    },
    toggleHowTo() {
      const val = this.$store.state.howTo;
      if (val == false) {
        this.$store.dispatch("setHowTo", true);
        this.$store.dispatch("setPreview", false);
      } else {
        this.$store.dispatch("setHowTo", false);
      }
    },
    clearSearch() {
      this.search = "";
    },
  },
};
</script>

<style scoped>
.databucket-name {
  padding-left: 6px;
  color: white !important;
  font-size: 0.85em;
}

.card-actions-left {
  position: absolute;
  bottom: 0;
}
.card-actions-right {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
