<template>
  <div>
    <div v-if="!newFileProcessActive">
      <v-row 
        class="ml-3 mb-3 incentable-form-heading"
        no-gutters
      >
        <v-col class="mt-3">
          Data Upload History
        </v-col>
        <v-col>
          <v-row 
            v-if="!newFileProcessActive"
            class="mx-2"
            no-gutters
            justify="end"
          >
            <v-btn 
              color="primary"
              elevation="0"
              @click="newFile"
              :disabled="!hasTargetData"
            >
              Upload New Data
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <div v-if="!hasTargetData" class="mt-16">
        <v-row no-gutters class="px-3" justify="center">
          <v-icon color="primary" size="60">fa fa-bullseye</v-icon>
        </v-row>
        <v-row no-gutters class="px-3 mt-2 incentable-page-subheading" justify="center">
          You need to upload targets before you can upload any progress data
        </v-row>
      </div>
      <div v-else-if="!actualData.length" class="mt-16">
        <v-row no-gutters class="px-3" justify="center">
          <v-icon color="primary" size="60">move_up</v-icon>
        </v-row>
        <v-row no-gutters class="px-3 mt-2 incentable-page-subheading" justify="center">
          No data has been uploaded
        </v-row>
      </div>
      <div v-else class="mt-3">
        <history />
      </div>
    </div>
    <div v-if="newFileProcessActive">
      <v-row 
        class="ml-3 mt-0 incentable-form-heading"
        no-gutters
      >
        Import New Actual Data
      </v-row>
      <v-stepper v-model="stage">
        <v-stepper-header>
          <v-stepper-step step="1">
            Select CSV
          </v-stepper-step>
            <v-divider></v-divider>
          <v-stepper-step step="2">
            Map Data Bucket to CSV
          </v-stepper-step>
            <v-divider></v-divider>
          <v-stepper-step step="3">
            Import Actual Data
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <select-csv />
            <v-row no-gutters class="mt-3" justify="end">
              <v-btn 
                elevation="0"
                color="primary"
                @click="cancelNewFile"
              >Cancel</v-btn>
              <v-btn v-if="csvStatus" @click="stage ++" elevation="0" class="white--text" color="primary">Next</v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <map-csv
              :targetFields="targetHeaders"
              :requiredFields="requiredFields"
              :sourceFields="csvHeaders"
              :sourceData="csvBody"
              :mapping="actualMapping"
              @editingMap="onEditingMap"
              class="mb-3"
              />
            <v-row justify="end" no-gutters>
              <v-btn color="primary" @click="stage --" elevation="0" text :disabled="editingMap">Back</v-btn>
              <v-btn color="primary" @click="stage ++" elevation="0" :disabled="editingMap">Next</v-btn>
            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <upload-csv />
            <v-row justify="end" no-gutters>
              <v-btn color="primary" @click="stage --" elevation="0" text>Back</v-btn>
              <v-btn 
                @click="handleUploadData"
                color="primary"
                elevation="0"
                :loading="isProcessing"
              >Proceed with import</v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>
  </div>
</template>

<script>
import History from './History.vue'
import SelectCsv from './SelectCsv.vue'
import MapCsv from './MapCsv.vue'
import UploadCsv from './UploadCsv.vue'

import moment from 'moment'

export default {
  components: {
    History,
    SelectCsv,
    MapCsv,
    UploadCsv
  },
  data () {
    return {
      stage: 1,
      requiredFields: ['accountKey'],
      newFileProcessActive: false,
      editingMap: false,
      isProcessing: false,
    }
  },
  created() {
    this.$store.dispatch('setCsvActualData', [])
  },
  computed: {
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    
    databucket() {
      return this.$store.state.databucket.databucket
    },

    entityMap() {
      return this.$store.getters.databucketEntityMap
    },

    hasTargetData() {
      const data = this.$store.state.databucket.targetData.data ? true : false
      return data
    },
    
    targetHeaders() {
      return this.databucket.hasTargets ?
        [
          'Account Key',
          ...this.targetCodes,
          'Remark 1',
          'Remark 2',
          'Remark 3',
        ]
      : [
        'Account Key',
        'Points',
        'Description',
      ];
    },

    actualMapping() {
      return this.databucket.actualMapping || {}
    },

    actualData() {
      return this.$store.state.databucket.actualData || []
    },
    
    consumedTargetHeaders() {
      const data = ['Account Key', ...this.consumedTargetCodes]
      const arr = data.sort()
      return arr
    },
    
    csvData() {
      return this.$store.state.databucket.csvActualData || []
    },

    csvStatus() {
      if (this.csvData.length > 0) {
        return true
      }
      return false
    },

    targetCodes() {
      return this.databucket.targetCodes;
    },
    
    targetMapping() {
      return this.databucket.targetMapping || {};
    },

    csvHeaders() {
      return this.csvData.length ?  this.csvData[0] : [];
    },

    csvBody() {
      return this.csvData.length ?  this.csvData.slice(1) : [];
    },

    matched() {
      const accountKeyIndex = this.csvHeaders.findIndex(header => header === this.targetMapping['Account Key']);

      return accountKeyIndex !== -1
        ? this.csvBody.filter((item) => {
          return this.entityMap[item[accountKeyIndex]]
        })
        : [];
    },

    newCsvActualFileName() {
      return this.$store.state.databucket.newCsvActualFileName || ''
    },
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format('ddd, MMM Do YYYY, h:mm a') : ''
    },
    checkMap(field) {
      const arr = this.csvHeaders
      const found = arr.find(item => item === field)
      return found || ''
    },
    getSampleRow(targetField) {
      if (!this.csvBody.length) {
        return '';
      }
      const relSource = this.newMapping[targetField];
      const colIndex = this.csvHeaders.findIndex(sourceField => sourceField === relSource);
      return this.csvBody[0][colIndex];
    },
    sortObj(val) {
      return Object.keys(val).sort().reduce((r, k) => (r[k] = val[k], r), {})
    },
    onEditingMap (val) {
      this.editingMap = val
    },
    newFile() {
      this.newFileProcessActive = true
    },
    cancelNewFile() {
      this.$store.dispatch('setCsvActualData', [])
      this.newFileProcessActive = false
    },
    handleUploadData() {
      this.isProcessing = true;

      const mappingEntries = Object.entries(this.actualMapping);
      const mappingIndices = mappingEntries.map(([, mVal]) => {
        return this.csvHeaders.findIndex(header => header === mVal);
      });

      const tcEntries = mappingEntries.filter(entry => this.targetCodes.find(targetCode => targetCode === entry[0]));
      const validCsvHeaders = this.csvHeaders.filter((header, headerIdx) => {
        const found = tcEntries.find(entry => entry[1] === header);
        if (!found) {
          return false;
        }

        let hasData = false;
        for (let i = 0; i < this.csvBody.length; i += 1) {
          const row = this.csvBody[i];
          if (row[headerIdx]) {
            hasData = true;
            break;
          }
        }

        return hasData;
      });
      // console.log(mappingEntries)
      // console.log(tcEntries)
      // console.log(validCsvHeaders)

      // const consumedTargetCodes = validCsvHeaders.map((header) => {
      //   const targetCodeEntry = tcEntries.find(entry => entry[1] === header);
      //   return targetCodeEntry[0];
      // });
      const consumedTargetCodes = tcEntries.reduce((acc, entry) => {
        const existing = validCsvHeaders.includes(entry[1]);
        if (existing) {
          return [...acc, entry[0]]
        }
        return acc;
      }, []);
      // console.log(consumedTargetCodes)

      const mappedData = this.matched.map((row) => {
        const rowResult = mappingEntries.reduce((rowAgg, mappingEntry, idx) => {
          if (mappingIndices[idx] === -1) {
            return rowAgg;
          }
          return {
            ...rowAgg,
            [mappingEntry[0]]: row[mappingIndices[idx]] || '',
          };

        }, {});

        return rowResult;
      });

      const payload = {
        fileName: this.newCsvActualFileName,
        data: mappedData,
        consumedTargetCodes,
        rows: this.csvBody.length,
        matched: this.matched.length,
      };

      this.$store.dispatch('createActualData', payload)
        .then(() => {
          this.isProcessing = false;
          this.resetUpload()
          this.reset();
        })
        .catch(() => this.isProcessing = false);
    },

    handleReverse(item) {
      this.$store.dispatch('deleteActualData', item.id)
        .then(() => {})
        .catch(() => {});
    },
    resetUpload() {
      this.$store.dispatch('setCsvActualData', [])
      this.$store.dispatch('setNewCsvActualFileName', '')
      this.mappingErrors = {}
      this.newFileProcessActive = false
      this.stage = 1
    },
  }
}
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
}
</style>