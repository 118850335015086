<template>
  <v-dialog v-model="open" max-width="1100px" persistent>
    <v-card class="pa-0">
      <form data-vv-scope="member">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
            absolute
            color="primary"
            :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>
        <v-row no-gutters>
          <v-col cols="4">
            <v-row no-gutters justify="center">
              <media-upload
                class="mt-n16 mb-3"
                :id="memberId"
                :currentImageOnFile="image"
                :externalImageUrl="externalImageUrl"
                :showMetaData="false"
                @onImageSaved="handleImageSaved"
                @onImageProcessing="handleImageProcessing"
                :radius="false"
                :avatar="true"
              />
            </v-row>
            <v-row no-gutters justify="center" class="incentable-member-name">
              {{ firstname }}
              {{ lastname }}
            </v-row>
            <v-row
              no-gutters
              justify="center"
              class="incentable-page-subheading mb-1"
            >
              {{ email }}
            </v-row>
            <v-row no-gutters justify="center">
              <span
                class="points-label link primary--text"
                @click="memberId ? handleOpenMemberPointsTable(memberId) : ''"
              >
                {{ memberPoints.balance | number }} {{ pointsLabel }}
              </span>
            </v-row>
          </v-col>

          <v-col class="mt-5" cols="3">
            <v-row no-gutters>
              <v-col>
                <v-row no-gutters align="end" class="mb-3">
                  <status-icon :status="status"></status-icon>
                  <span class="ml-1">{{ status }}</span>
                </v-row>
                <v-row no-gutters>
                  <v-icon>account_balance_wallet</v-icon>
                  <span class="incentable-page-subheading mt-1 ml-1"
                    >{{ memberPoints.balance | number }} Available</span
                  >
                </v-row>
                <v-row no-gutters>
                  <v-icon>stars</v-icon>
                  <span class="incentable-page-subheading mt-1 ml-1"
                    >{{ memberPoints.awarded | number }} Awarded</span
                  >
                </v-row>
                <v-row no-gutters>
                  <v-icon>shopping_cart</v-icon>
                  <span class="incentable-page-subheading mt-1 ml-1"
                    >{{ memberPoints.redeemed | number }} Redeemed</span
                  >
                </v-row>
                <v-row no-gutters>
                  <v-icon>vpn_key</v-icon>
                  <span class="incentable-page-subheading mt-1 ml-1">{{
                    accountKey
                  }}</span>
                  <span v-if="!accountKey" class="no-data">No Key</span>
                </v-row>
                <v-row no-gutters class="mt-0">
                  <v-icon>business</v-icon>
                  <v-chip
                    v-for="id in selectedCompanies"
                    :key="id"
                    class="ma-1"
                  >
                    <company-name :id="id"></company-name>
                  </v-chip>
                  <span v-if="!selectedCompanies.length" class="no-data"
                    >No Company Selected</span
                  >
                </v-row>

                <v-row no-gutters class="mt-0">
                  <v-icon>local_offer</v-icon>
                  <v-chip v-for="id in selectedTags" :key="id" class="ma-1">
                    <member-tag-name :id="id"></member-tag-name>
                  </v-chip>
                  <span v-if="!selectedTags.length" class="no-data"
                    >No Tag Selected</span
                  >
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <v-col class="mt-5">
            <v-row no-gutters class="mt-0 mb-n1"> Tier </v-row>

            <v-row no-gutters class="mt-3">
              <v-col>
                <v-chip
                  pill
                  color="transparent"
                  label
                  class="mr-2"
                  no-gutters
                  v-for="(tier, id) in memberQualifiedTierData"
                  :key="id"
                >
                  <v-avatar v-if="tier.image.smallUrl" left tile>
                    <v-img :src="tier.image.smallUrl"></v-img>
                  </v-avatar>
                  <span>
                    {{ tier.tier }}
                  </span>
                </v-chip>
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-3 mb-n1"> Awards </v-row>

            <v-row no-gutters class="mt-3">
              <v-col>
                <v-chip
                  pill
                  color="transparent"
                  label
                  class="mr-2"
                  no-gutters
                  v-for="(award, id) in memberQualifiedAwardData"
                  :key="id"
                >
                  <v-avatar v-if="award.image.smallUrl" left tile>
                    <v-img :src="award.image.smallUrl"></v-img>
                  </v-avatar>
                  <span>
                    {{ award.award }}
                  </span>
                </v-chip>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-tabs v-model="tab" background-color="grey lighten-4" class="mt-4">
          <v-tab class="tab"> Profile </v-tab>
          <v-tab v-if="memberId" class="tab"> Other </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <v-row class="px-10 pt-8">
              <v-col>
                <v-text-field
                  v-model="firstname"
                  label="First Name"
                  v-validate="'required'"
                  :error-messages="errors.collect('member.firstname')"
                  name="firstname"
                  type="text"
                  dense
                />

                <v-text-field
                  v-model="lastname"
                  label="Last Name"
                  v-validate="'required'"
                  :error-messages="errors.collect('member.lastname')"
                  name="lastname"
                  type="text"
                  dense
                />
                <v-text-field
                  v-model="email"
                  label="Email"
                  v-validate="'required|email'"
                  :error-messages="errors.collect('member.email')"
                  name="email"
                  type="email"
                  append-icon="email"
                  dense
                />
                <v-text-field
                  v-model="phone"
                  label="Phone"
                  append-icon="phone"
                  dense
                />
                <v-text-field
                  v-model="mobile"
                  label="Mobile"
                  append-icon="smartphone"
                  dense
                />
              </v-col>

              <v-col>
                <v-text-field
                  v-model="accountKey"
                  label="Data Key"
                  name="accountKey"
                  type="text"
                  append-icon="vpn_key"
                  dense
                />

                <v-select
                  v-model="selectedCompanies"
                  :items="companies"
                  item-text="title"
                  item-value="id"
                  label="Companies"
                  chips
                  multiple
                  deletable-chips
                  append-icon="business"
                  outlined
                  class="mt-3"
                />

                <v-select
                  v-model="selectedTags"
                  :items="memberTags"
                  item-text="tag"
                  item-value="id"
                  label="Member Tags"
                  chips
                  multiple
                  deletable-chips
                  append-icon="local_offer"
                  outlined
                >
                </v-select>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item
            v-if="memberId"
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <v-row class="px-10 pt-8">
              <v-col>
                <v-text-field :value="created | date" label="Created" readonly>
                </v-text-field>

                <v-text-field :value="updated | date" label="Updated" readonly>
                </v-text-field>

                <v-text-field
                  v-if="activated"
                  :value="activated | date"
                  label="Activated"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  v-if="!activated"
                  value="Not Activated"
                  label="Activated"
                  readonly
                />

                <v-text-field :value="memberId" label="System Id" readonly />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="externalImageUrl"
                  label="External Image Url"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>

        <v-row v-if="errorMessage" no-gutters class="mx-10">
          <v-col>
            <v-alert color="primary" text border="left">
              {{ errorMessage }}
            </v-alert>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()" :disabled="loading">
            {{ memberId ? "Close" : "Cancel" }}
          </v-btn>
          <v-btn
            @click="handleSave('member', true)"
            color="primary"
            :loading="loading"
            elevation="0"
            :disabled="demo || loading"
          >
            {{ memberId ? "Save Member" : "Create Member" }}
          </v-btn>
        </v-card-actions>
      </form>

      <member-points-table
        v-if="memberId"
        :memberId="transactionsMemberId"
        :open="openMemberPointsTable"
        @onClose="handleCloseMemberPointsTable"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import { AVATAR_COLORS } from "@/constants/colors";
import MediaUpload from "../Shared/MediaUpload.vue";
import MemberPointsTable from "../RedeemablePoints/MemberForm/MemberPointsTable.vue";

export default {
  components: {
    MemberPointsTable,
    MediaUpload,
  },
  $_veeValidate: {
    validator: "new",
  },

  created() {
    this.randomColor = this.getRandomColor();
    this.$store.dispatch("loadTiers");
    this.$store.dispatch("loadMemberQualifiedTierData");
    this.$store.dispatch("loadAwards");
    this.$store.dispatch("loadMemberQualifiedAwardData");
  },

  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: ["open", "memberId"],
  data: () => ({
    dictionary: {
      custom: {
        firstname: {
          required: () => "First name is required",
        },
        lastname: {
          required: () => "Last name is required",
        },
        email: {
          required: () => "Email is required",
        },
      },
    },
    openMemberPointsTable: false,
    transactionsMemberId: "",
    tab: null,
    formTitle: "Profile Picture",
    formIcon: "image",
    selectedStatus: "",
    selectedCategorys: [],
    selectedBlog: "",
    selectedCompanyTags: [],
    selectedMemberTags: [],
    externalImageUrl: "",
    filename: "",
    created: "",
    updated: "",
    createdBy: "",
    updatedBy: "",
    image: {
      name: "",
      updated: "",
      smallUrl: "",
      mediumUrl: "",
      largeUrl: "",
      smallStoragePath: "",
      mediumStoragePath: "",
      largeStoragePath: "",
    },
    dialogNewOrEditOpen: false,
    trigger: false,
    message: "ready",
    randomColor: "",
    showAlert: false,
    selectedTags: [],
    selectedCompanies: [],
    firstname: "",
    lastname: "",
    transactions: [],
    initials: "",
    color: "",
    email: "",
    id: "",
    phone: "",
    mobile: "",
    balance: "",
    awarded: "",
    redeemed: "",
    status: "Pending",
    accountKey: "",
    activated: "",
    errorMessage: "",
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    programId() {
      return this.$store.getters.programId;
    },
    pointsLabel() {
      return this.$store.getters.currentProgram.points.label;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    companies() {
      return this.$store.state.company.companies;
    },
    members() {
      return this.$store.state.member.members;
    },
    memberPoints() {
      var member = this.members.find((el) => el.id === this.memberId);
      if (member) {
        return {
          balance: member.balance ? member.balance : "",
          awarded: member.awarded ? member.awarded : "",
          redeemed: member.redeemed ? member.redeemed : "",
        };
      }
      return {
        balance: "",
        awarded: "",
        redeemed: "",
      };
    },
    tiers() {
      return this.$store.state.tier.tiers;
    },
    memberQualifiedTierData() {
      const qualified = this.$store.state.tier.memberQualifiedTierData
        .filter((el) => el.accountId === this.accountKey)
        .map(({ tier, image }) => ({ tier, image }));

      let defaultTier = [];
      if (this.tiers.length > 0) {
        defaultTier = this.tiers
          .filter((el) => el.isDefault === true)
          .map(({ tier, image }) => ({ tier, image }));
      }
      return qualified.length > 0 ? qualified : defaultTier;
    },
    awards() {
      return this.$store.state.award.awards;
    },
    memberQualifiedAwardData() {
      const qualified = this.$store.state.award.memberQualifiedAwardData
        .filter((el) => el.accountId === this.accountKey)
        .map(({ award, image }) => ({ award, image }));

      let defaultAward = [];
      if (this.awards.length > 0) {
        defaultAward = this.awards
          .filter((el) => el.isDefault === true)
          .map(({ award, image }) => ({ award, image }));
      }
      return qualified.length > 0 ? qualified : defaultAward;
    },
  },

  watch: {
    memberId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedMember = this.members.find(
            (item) => item.id === newVal
          );
          const {
            email,
            balance,
            awarded,
            redeemed,
            firstname,
            lastname,
            token,
            initials,
            color,
            image,
            accountKey,
            companies,
            tags,
            created,
            updated,
            activated,
            status,
            externalImageUrl,
            phone,
            mobile,
          } = selectedMember;
          this.status = status;
          this.token = token;
          this.firstname = firstname;
          this.lastname = lastname;
          this.initials = initials || "";
          this.color = color || this.randomColor;
          this.email = email;
          this.phone = phone;
          this.mobile = mobile;
          this.created = created;
          this.updated = updated;
          this.activated = activated;
          this.accountKey = accountKey;
          this.selectedTags = tags;
          this.selectedCompanies = companies;
          this.awarded = awarded;
          this.redeemed = redeemed;
          this.balance = balance;
          this.externalImageUrl = externalImageUrl;
          this.image = image || {
            name: "",
            updated: "",
            smallUrl: "",
            mediumUrl: "",
            largeUrl: "",
            smallStoragePath: "",
            mediumStoragePath: "",
            largeStoragePath: "",
          };
        }
      },
    },
  },

  methods: {
    handleImageSaved(image) {
      // new uploader
      (this.image = {
        updated: image.updated,
        name: image.name || "",
        smallUrl: image.smallUrl || "",
        mediumUrl: image.mediumUrl || "",
        largeUrl: image.largeUrl || "",
        smallStoragePath: image.smallStoragePath || "",
        mediumStoragePath: image.mediumStoragePath || "",
        largeStoragePath: image.largeStoragePath || "",
      }),
        this.handleSave("member", false);
    },
    handleImageProcessing(val) {
      // new uploader
      this.$store.dispatch("setLoading", val);
    },
    handleNew() {
      // Open New form
      this.selectedImageId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedImageId = "";
      this.dialogNewOrEditOpen = false;
    },
    handleOpenMemberPointsTable(id) {
      this.$store.dispatch("loadTransactions", this.memberId);
      this.transactionsMemberId = id;
      this.openMemberPointsTable = true;
    },
    handleCloseMemberPointsTable() {
      this.openMemberPointsTable = false;
      this.transactionsMemberId = "";
    },
    getMemberTier(id) {
      this.$store.dispatch("getMemberTier", id);
    },
    getRandomColor() {
      const colors = AVATAR_COLORS;
      const random = Math.floor(Math.random() * colors.length);
      const result = colors[random];
      return result;
    },
    getInitials(firstname, lastname, initials) {
      if (initials.length > 0) {
        return initials;
      } else {
        const name = firstname + " " + lastname;
        return name
          .match(/(\b\S)?/g)
          .join("")
          .match(/(^\S|\S$)?/g)
          .join("")
          .toUpperCase();
      }
    },
    handleClose() {
      this.$emit("onClose");
      this.clear();
    },
    clear() {
      (this.tab = null), (this.firstname = "");
      this.lastname = "";
      this.initials = "";
      this.points = "";
      (this.awarded = ""),
        (this.balance = ""),
        (this.redeemed = ""),
        (this.color = "");
      this.randomColor = this.getRandomColor();
      this.created = "";
      this.updated = "";
      this.activated = "";
      this.email = "";
      this.mobile = "";
      this.phone = "";
      this.status = "Pending";
      this.accountKey = "";
      this.selectedTags = [];
      this.selectedCompanies = [];
      (this.errorMessage = ""), (this.externalImageUrl = "");
      this.$validator.reset();
      this.$store.dispatch("setLoading", false);
      this.image = {
        // new uploader
        name: "",
        updated: "",
        smallUrl: "",
        mediumUrl: "",
        largeUrl: "",
        smallStoragePath: "",
        mediumStoragePath: "",
        largeStoragePath: "",
      };
    },

    handleSave(scope, close) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }
        this.$store.dispatch("setLoading", true);

        const payload = {
          id: this.memberId || "",
          firstname: this.firstname.trim(),
          lastname: this.lastname.trim(),
          color: this.color || this.getRandomColor(),
          fullnameUppercase: (this.firstname + this.lastname)
            .toUpperCase()
            .trim(),
          email: this.email.toLowerCase().trim(),
          mobile: this.mobile || "",
          phone: this.phone || "",
          accountKey: this.accountKey || "",
          tags: this.selectedTags || [],
          companies: this.selectedCompanies || [],
          externalImageUrl: this.externalImageUrl || "",
          image: this.image || {
            name: "",
            updated: "",
            smallUrl: "",
            mediumUrl: "",
            largeUrl: "",
            smallStoragePath: "",
            mediumStoragePath: "",
            largeStoragePath: "",
          },
        };

        // If create mode
        if (!this.memberId) {
          payload.status = "Pending";
        }

        const action = this.memberId ? "updateMember" : "createMember";

        this.$store
          .dispatch(action, payload)
          .then(() => {
            this.$store.dispatch("setLoading", false);
            if (close) {
              this.handleClose();
              return;
            }
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch("setLoading", false);
          });
      });
    },
  },
};
</script>

<style>
.camera {
  z-index: 1000;
  margin-top: 72px;
  margin-left: 175px;
  cursor: pointer !important;
}
.incentable-overlay {
  cursor: pointer !important;
}
.incentable-name {
  font-family: "Figtree", sans-serif !important;
  font-size: 22px !important;
  font-weight: 500 !important;
}
.points-label {
  font-family: "Figtree", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.no-data {
  font-family: "Figtree", sans-serif !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  color: rgb(180, 180, 180) !important;
  font-style: italic !important;
  padding-top: 4px !important;
  margin-left: 5px !important;
}
.avatar {
  font-family: "Figtree", sans-serif !important;
  font-size: 30px !important;
  font-weight: 300 !important;
  color: #fff;
}
</style>
