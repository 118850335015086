<template>
  <v-dialog v-model="open" max-width="800px" persistent>
    <v-card>
      <form data-vv-scope="company">
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
            absolute
            color="primary"
            :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="80" color="primary">{{ formIcon }}</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            {{ companyId ? "Edit " : "Add New " }}
            {{ formTitle }}
          </v-card-title>
        </v-row>

        <v-card-text class="px-16">
          <v-row dense>
            <v-col cols="8" class="pr-16">
              <v-text-field
                name="title"
                label="Company Name"
                v-validate="'required'"
                :error-messages="errors.collect('company.title')"
                v-model="title"
              ></v-text-field>

              <v-text-field
                name="accountKey"
                label="Data Key"
                v-model="accountKey"
                append-icon="vpn_key"
              ></v-text-field>

              <v-select
                v-model="selectedMembers"
                :items="companyMembers"
                :item-text="getFullName"
                item-value="id"
                label="Members"
                chips
                multiple
                deletable-chips
                append-icon="person"
              >
                <template v-slot:selection="{ item }">
                  <v-chip pill close @click:close="removeMember(item.id)">
                    <v-avatar v-if="item.image.smallUrl" left class="mr-2">
                      <v-img
                        :src="item.image.smallUrl"
                        :lazy-src="item.image.smallUrl"
                      ></v-img>
                    </v-avatar>
                    <v-avatar
                      v-else
                      left
                      :color="item.color || grey"
                      class="mr-2"
                    >
                      <span class="white--text">{{ item.initials }}</span>
                    </v-avatar>
                    {{ item.firstname }} {{ item.lastname }}
                  </v-chip>
                </template>
              </v-select>
            </v-col>

            <v-col lg="4">
              <v-select
                name="status"
                label="Status"
                v-validate="'required'"
                :error-messages="errors.collect('company.status')"
                v-model="selectedStatus"
                :items="statuses"
                item-text="text"
                item-value="value"
                append-icon="power_settings_new"
              >
              </v-select>

              <v-row no-gutters class="incentable-form-heading">
                <v-col> Tags </v-col>
              </v-row>
              <v-row no-gutters class="incentable-form-subheading">
                <v-col> Add tags to manage content visibility </v-col>
              </v-row>

              <v-select
                v-model="selectedTags"
                :items="companyTags"
                item-text="tag"
                item-value="id"
                label="Company Tags"
                chips
                multiple
                deletable-chips
                append-icon="local_offers"
              >
              </v-select>

              <div v-if="companyId">
                <v-row no-gutters class="incentable-form-heading">
                  <v-col> More </v-col>
                </v-row>
                <v-row no-gutters class="incentable-form-subheading">
                  <v-col> Additional information </v-col>
                </v-row>

                <v-text-field
                  :value="created | convertTimestamp"
                  label="Created"
                  readonly
                >
                </v-text-field>

                <v-text-field
                  :value="updated | convertTimestamp"
                  label="Updated"
                  readonly
                >
                </v-text-field>
              </div>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert type="error" :value="true">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">
            {{ companyId ? "Close" : "Cancel" }}
          </v-btn>
          <v-btn
            @click="handleSave('company')"
            color="primary"
            :class="orgTheme.buttonTextColor"
            :loading="loading"
            :disabled="demo"
            elevation="0"
          >
            {{ companyId ? "Save Company" : "Create Company" }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },

  created() {},

  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  props: {
    companyId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    dictionary: {
      custom: {
        title: {
          required: () => "Company name is required",
        },
      },
    },
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    formTitle: "Company",
    formIcon: "business",
    showAlert: false,
    selectedStatus: "",
    selectedTags: [],
    selectedMembers: [],
    title: "",
    created: "",
    updated: "",
    accountKey: "",
    errorMessage: "",
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    companies() {
      return this.$store.state.company.companies;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    companyMembers() {
      return this.$store.state.member.members;
    },
  },

  watch: {
    companyId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedCompany = this.companies.find(
            (item) => item.id === newVal
          );
          const { title, accountKey, status, members, tags, created, updated } =
            selectedCompany;
          this.title = title;
          this.created = created;
          this.updated = updated;
          this.accountKey = accountKey;
          this.selectedStatus = status || "Active";
          this.selectedMembers = members;
          this.selectedTags = tags;
        }
      },
    },
  },

  methods: {
    removeMember(item) {
      this.selectedMembers.splice(this.selectedMembers.indexOf(item), 1);
    },
    getFullName(member) {
      return `${member.firstname} ${member.lastname}`;
    },
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    clear() {
      this.title = "";
      this.created = "";
      this.updated = "";
      this.createdBy = "";
      this.updatedBy = "";
      this.accountKey = "";
      this.selectedStatus = "";
      this.selectedTags = [];
      this.selectedMembers = [];
      (this.errorMessage = ""), this.$validator.reset();
      this.$store.dispatch("setLoading", false);
    },
    handleSave(scope) {
      this.$validator.validateAll(scope).then((result) => {
        if (!result) {
          return;
        }

        this.$store.dispatch("setLoading", true);

        const payload = {
          id: this.companyId || "",
          status: this.selectedStatus,
          title: this.title || "",
          accountKey: this.accountKey || "",
          tags: this.selectedTags || [],
          members: this.selectedMembers || [],
          titleUppercase: this.title.toUpperCase() || "",
          points: 0,
        };

        const action = this.companyId ? "updateCompany" : "createCompany";

        this.$store
          .dispatch(action, payload)
          .then(() => {
            this.$store.dispatch("setLoading", false);
            this.handleClose();
          })
          .catch((e) => {
            this.errorMessage = e;
            this.$store.dispatch("setLoading", false);
          });
      });
    },
  },
};
</script>

<style></style>
