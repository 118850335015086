<template>
  <div>
    <v-app-bar
      class="page-bar"
      dense
      fixed
      color="grey lighten-4"
      elevate-on-scroll
    >
      <v-row no-gutters justify="center" class="mx-9">
        <v-col cols="7" class="pt-2">
          <span class="page-heading">{{ pageTitle }}</span>
        </v-col>
        <v-col cols="auto">
          <v-btn color="primary" elevation="0" @click="goback">go back</v-btn>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-row no-gutters justify="center" class="mx-16 mt-14">
      <v-col cols="12" lg="8">
        <v-stepper v-model="stage" vertical>
          <!----------------------------------------------->
          <v-stepper-step step="1" :complete="stage > 1">
            Title
            <span v-if="stage > 1" class="table-subtitle pt-1">{{
              newProgramData.title
            }}</span>
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-row no-gutters class="pt-1">
              <v-col md="6" xs="10">
                <v-text-field
                  outlined
                  v-model="newProgramData.title"
                  label="Program Title"
                  v-validate="'required'"
                  :error-messages="errors.first('title')"
                  data-vv-name="title"
                  type="text"
                  required
                ></v-text-field>
              </v-col>
              <v-col md="4" xs="2" class="pt-4">
                <span v-if="titleExists" class="ml-4 incentable-card-subtitle"
                  ><v-icon color="red" class="pr-2">cancel</v-icon>Not
                  Available</span
                >
                <span
                  v-else-if="!titleInvalid"
                  class="ml-4 incentable-card-subtitle"
                  ><v-icon color="green" class="pr-2">check_circle</v-icon
                  >Available</span
                >
              </v-col>
            </v-row>
            <v-row no-gutters class="ml-n1">
              <v-btn
                color="primary"
                @click="nextStep()"
                elevation="0"
                :disabled="titleInvalid || titleExists"
              >
                Next
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="2" :complete="stage > 2">
            Domain
            <span v-if="stage > 2" class="table-subtitle pt-1"
              >{{ newProgramData.url.toLowerCase() }}.incentable.com</span
            >
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-row no-gutters class="pt-1">
              <v-col md="6" xs="10">
                <v-text-field
                  v-validate="'required|alpha_num|min:2|max:50'"
                  :error-messages="errors.collect('Subdomain')"
                  data-vv-name="Subdomain"
                  v-model="newProgramData.url"
                  label="Subdomain"
                  suffix=".incentable.com"
                  type="text"
                  required
                  outlined
                ></v-text-field>
              </v-col>
              <v-col md="4" xs="2" class="pt-4">
                <span v-if="domainExists" class="ml-4 incentable-card-subtitle"
                  ><v-icon color="red" class="pr-2">cancel</v-icon>Not
                  Available</span
                >
                <span
                  v-else-if="!domainInvalid"
                  class="ml-4 incentable-card-subtitle"
                  ><v-icon color="green" class="pr-2">check_circle</v-icon
                  >Available</span
                >
              </v-col>
            </v-row>
            <v-row no-gutters class="ml-n1">
              <v-btn
                color="primary"
                @click="nextStep()"
                elevation="0"
                :disabled="domainInvalid"
              >
                Next
              </v-btn>
              <v-btn color="primary" @click="backStep()" elevation="0" text>
                Back
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="3" :complete="stage > 3">
            Theme
            <span v-if="themeName" class="table-subtitle pt-1">{{
              themeName
            }}</span>
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-row no-gutters justify="space-between" class="mx-3">
              <v-col cols="8" class="pr-2">
                <v-select
                  class="mt-1 caption"
                  :items="themeTags"
                  v-model="tagFilter"
                  multiple
                  solo-inverted
                  dense
                  flat
                  chips
                  small-chips
                  deletable-chips
                  clearable
                  hide-details
                  prepend-inner-icon="filter_list"
                  placeholder="Filter By Tag"
                ></v-select>
              </v-col>
              <v-col cols="4" class="pl-4">
                <v-text-field
                  class="mt-1 caption"
                  v-model="search"
                  solo-inverted
                  dense
                  flat
                  hide-details
                  prepend-inner-icon="search"
                  clearable
                  placeholder="Search By Title"
                  @click:clear="clearSearch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <themes
                  mode="selector"
                  @onSelectTheme="onSelectTheme"
                  :search="search"
                  :programs="filteredProgramThemes"
                />
              </v-col>
            </v-row>
            <v-row no-gutters class="ml-n1">
              <v-btn color="primary" @click="nextStep()" elevation="0">
                Next
              </v-btn>
              <v-btn color="primary" @click="backStep()" elevation="0" text>
                Back
              </v-btn>
            </v-row>
          </v-stepper-content>
          <!----------------------------------------------->
          <v-stepper-step step="4" :complete="stage > 4">
            Select Plan
          </v-stepper-step>
          <v-stepper-content step="4" class="mx-0">
            <plans-table :newProgramData="newProgramData" />
            <v-btn
              color="primary"
              @click="backStep()"
              elevation="0"
              text
              :disabled="loading"
            >
              Back
            </v-btn>
          </v-stepper-content>
          <!----------------------------------------------->
        </v-stepper>
        <v-alert
          v-model="titleError"
          color="primary"
          border="left"
          dense
          text
          type="info"
          class="incentable-alert mx-6 mt-0"
        >
          The Program Title you have entered is not available. Use the "Back"
          buttons to go back and change it.
        </v-alert>
        <v-alert
          v-model="urlError"
          color="primary"
          border="left"
          dense
          text
          type="info"
          class="incentable-alert mx-6 mt-0"
        >
          That Subdomain you have entered is not available. Use the "Back"
          buttons to go back and change it.
        </v-alert>
        <legal-links />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { RESERVED_DOMAINS } from "@/constants/subdomain.js";
import Themes from "../Programs/Components/Themes.vue";
import PlansTable from "../Setup/PlansTable.vue";
import LegalLinks from "../../Shared/LegalLinks.vue";

export default {
  $_veeValidate: {
    validator: "new",
  },
  components: { Themes, PlansTable, LegalLinks },
  data() {
    return {
      product: null,
      term: null,
      search: "",
      tagFilter: "",
      paid: false,
      page: 1,
      perPage: 9,
      stage: 1,
      themeName: "",
      newProgramData: {
        title: "",
        url: "",
        webTheme: {},
      },
      dictionary: {
        custom: {
          title: {
            required: () => "A program title is required",
          },
          Subdomain: {
            required: () => "A program subdomain is required",
            alpha_num:
              "Subdomain can only contain alphabetic characters or numbers without spaces",
          },
        },
      },
    };
  },
  created() {},
  mounted() {
    this.$store.dispatch("clearCurrentProgram"),
      this.$store.dispatch("initialize"),
      this.$store.commit("setNavMenu", false),
      this.$store.dispatch("setToolbar", true),
      this.$store.dispatch("setFooter", true),
      this.$store.dispatch("loadProgramsQuickList"),
      this.$store.commit("setLoadingCards", true),
      this.$store.dispatch("loadProgramThemes");
    this.$validator.localize("en", this.dictionary);
  },
  computed: {
    programs() {
      return this.$store.state.program.programsQuickList;
    },
    loading() {
      return this.$store.getters.loading;
    },
    programThemes() {
      return this.$store.state.program.programThemes;
    },
    titleError() {
      return this.$store.getters.prgTitleExists;
    },
    urlError() {
      return this.$store.getters.prgUrlExists;
    },
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    setupMode() {
      return this.$store.getters.setupMode;
    },
    pageTitle() {
      if (this.setupMode === "trial") {
        return "14 Day Free Trial Program Set Up";
      }
      return "New Program Set Up";
    },
    filteredProgramThemes() {
      const arr = this.tagFilter.length
        ? this.programThemes.filter((x) =>
            x.webTheme.tags.some((g) => this.tagFilter.includes(g))
          )
        : this.programThemes;
      const partial = arr.slice(0, this.page * this.perPage);
      return partial;
    },
    themeTags() {
      const array = this.programThemes;
      let result = [];
      array.forEach((el) => {
        result = [...result.concat(el.webTheme.tags)];
      });
      const uniq = [...new Set(result)].sort();
      console.uniq;
      if (uniq.length > 0) {
        return uniq;
      }
      return [];
    },
    currentTitles() {
      return this.programs.map((el) => {
        return el["titleUppercase"];
      });
    },
    currentUrls() {
      const programUrls = this.programs.map((el) => {
        return el["url"];
      });
      return programUrls.concat(RESERVED_DOMAINS);
    },
    titleExists() {
      var title = this.newProgramData.title.toUpperCase();
      const result = this.currentTitles.includes(title);
      if (result) {
        return true;
      }
      return false;
    },
    titleInvalid() {
      if (!this.newProgramData.title || this.$validator.errors.has("title")) {
        return true;
      } else {
        if (this.currentTitles.includes(this.newProgramData.title)) {
          return true;
        }
        return false;
      }
    },
    domainExists() {
      var completeUrl =
        this.newProgramData.url.toLowerCase() + ".incentable.com";
      const result = this.currentUrls.includes(completeUrl);
      if (result) {
        return true;
      }
      return false;
    },
    domainInvalid() {
      if (!this.newProgramData.url || this.$validator.errors.has("Subdomain")) {
        return true;
      } else {
        var completeUrl =
          this.newProgramData.url.toLowerCase() + ".incentable.com";
        if (this.currentUrls.includes(completeUrl)) {
          return true;
        }
        return false;
      }
    },
  },
  methods: {
    nextStep() {
      this.stage++;
    },
    backStep() {
      this.stage--;
    },
    goback() {
      this.$store.dispatch("setSetupMode", null);
    },
    clearSearch() {
      this.search = "";
    },
    onSelectTheme(val) {
      this.newProgramData.webTheme = val;
      this.themeName = val.title;
      this.stage++;
    },
  },
};
</script>

<style scoped>
.v-stepper__header {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.v-sheet.v-stepper:not(.v-sheet--outlined) {
  -webkit-box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
  box-shadow: 0 0px 0px 0px rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%),
    0 0px 0px 0 rgb(0 0 0 / 0%);
}
.page-bar {
  margin-top: 96px !important;
}
</style>
