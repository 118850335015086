<template>
  <div class="mx-3">
    <v-row>
      <v-col cols="4">
        <v-select
          :items="availableStatus"
          v-model="status"
          item-text="label"
          item-value="value"
          label="Status"
        />
        <v-text-field
          label="Display Order"
          type="number"
          name="order"
          v-model="order"
          v-validate="'required'"
          :error-messages="errors.collect('offerSettings.order')"
        />
        <v-text-field label="Created" readonly :value="created | date" />
        <v-text-field :value="updated | date" label="Updated" readonly />
      </v-col>
    </v-row>
    <v-row no-gutters class="ml-n1">
      <v-btn
        color="primary"
        :disabled="isProcessing"
        @click="openDeleteDialog = true"
        elevation="0"
        >Delete Offer</v-btn
      >
    </v-row>

    <v-dialog v-model="openDeleteDialog" max-width="400px" persistent>
      <v-card>
        <v-row no-gutters v-if="hasEntries">
          <span class="page-heading ml-4 pt-4"> Sorry, no can do </span>
        </v-row>
        <v-row v-else no-gutters>
          <v-card-title class="page-heading ml-4 pt-4">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-6 pb-6 pt-6">
          <v-row no-gutters v-if="hasEntries">
            <v-col>
              This Offer cannot be deleted as it has claims. Please change the
              status to Archive instead
            </v-col>
          </v-row>
          <v-row no-gutters v-else>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ title }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleCloseDeleteDialog()">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo || hasEntries"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  $_veeValidate: {
    validator: "new",
  },

  mounted() {
    this.$validator.localize("en", this.dictionary);
  },

  data: function () {
    return {
      isProcessing: false,
      openDeleteDialog: false,
      availableStatus: [
        { label: "Active", value: "Active" },
        { label: "Inactive", value: "Inactive" },
        { label: "Archive", value: "Archive" },
      ],
    };
  },
  dictionary: {
    custom: {
      title: {
        required: () => "Offer Name is required",
      },
      description: {
        required: () => "Offer description is required",
      },
      order: {
        required: () => "Order is required",
      },
    },
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    loading() {
      return this.$store.getters.loading;
    },
    hasEntries() {
      if (this.currentOffer.entries.length > 0) {
        return true;
      }
      return false;
    },
    title: {
      get() {
        return this.currentOffer.title;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", {
          title: value,
          titleUppercase: value.toUpperCase(),
        });
      },
    },
    createdBy() {
      return this.currentOffer.createdBy;
    },
    displayTitle: {
      get() {
        return this.currentOffer.displayTitle;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", {
          displayTitle: value,
          displayTitleUppercase: value.toUpperCase(),
        });
      },
    },
    created() {
      return this.currentOffer.created;
    },
    status: {
      get() {
        return this.currentOffer.status;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", { status: value });
      },
    },
    order: {
      get() {
        return this.currentOffer.order;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", { order: Number(value) });
      },
    },
    updatedBy() {
      return this.currentOffer.updatedBy;
    },
    selectedMemberTags: {
      get() {
        return this.currentOffer.memberTags;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", { memberTags: value });
      },
    },
    updated() {
      return this.currentOffer.updated;
    },
    selectedCompanyTags: {
      get() {
        return this.currentOffer.companyTags;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", { companyTags: value });
      },
    },
    description: {
      get() {
        return this.currentOffer.description;
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", { description: value });
      },
    },
  },
  methods: {
    handleDelete() {
      this.isProcessing = true;
      this.$store
        .dispatch("deleteOffer")
        .then(() => {
          this.isProcessing = false;
          this.$router.push(`/offers/${this.programId}`);
        })
        .catch(() => {});
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.openDeleteDialog = false;
    },
  },
};
</script>

<style scoped></style>
