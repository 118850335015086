<template>
  <div class="mx-3">
    <v-data-table
      :headers="headers"
      :items="actualData"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | date }}
      </template>  
      <template v-slot:[`item.actions`]="{ item }">
        <span
          @click="handleReverse(item)"
          class="red--text pointer"
        >
          <v-icon color="red" class="pr-1">undo</v-icon>
          Undo Import
        </span>  
      </template>  
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isProcessing: false,
      search: '',
      // Table columns
      headers: [
        { text: 'Date', value: 'created', align: 'left', sortable: true },
        { text: 'File Name', value: 'fileName', align: 'left', sortable: true },
        { text: 'Records', value: 'data.length', align: 'left', sortable: true },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false }
      ],
      // Table settings
      options: {
        sortBy: ['created'],
        sortDesc: [true],
        itemsPerPage: 10,
        sortable: true,
        multiSort: true,
        search: false,
        filter: false,
        upload: false,
        download: false,
        more: false,
      },
    }
  },

  computed: {
    actualData() {
      return this.$store.state.databucket.actualData;
    },
    loadingTable () {
      return this.$store.getters.loadingTable
    },
},

  methods: {
    handleReverse(item) {
      this.isProcessing = true;

      this.$store.dispatch('deleteActualData', item.id)
        .then(() => {
          this.isProcessing = false;
        })
        .catch(() => this.isProcessing = false);
    }
  },
}
</script>

<style scoped>
.pointer {
  cursor: pointer !important;
}

</style>
