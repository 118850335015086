<template>
  <div class="mx-3">
    <v-row no-gutters class="incentable-form-subheading">
      Attach files to this offer that members can download, to assist with their
      submission
    </v-row>
    <v-row>
      <v-card
        min-height="130px"
        width="130px"
        class="mt-2 mb-6 px-3"
        flat
        v-for="(item, index) in resources"
        :key="index"
      >
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            icon
            x-small
            @click="handleDeleteResource(item)"
            :disabled="demo"
          >
            <v-icon class="float-right">close</v-icon>
          </v-btn>
        </v-row>
        <v-row justify="center" align="stretch" class="text-center">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a v-bind="attrs" v-on="on" class="link" v-if="demo">
                <v-icon
                  class="mt-n3"
                  size="60"
                  :color="fileIcon(item.description).color"
                  >{{ fileIcon(item.description).icon }}
                </v-icon>
                <v-row
                  justify="center"
                  align="stretch"
                  class="text-center filename mt-2 mb-1 grey--text px-3"
                >
                  {{ item.description || "No file name" }}
                </v-row>
              </a>
              <a
                v-bind="attrs"
                v-on="on"
                :href="item.url"
                target="_blank"
                class="link"
                v-else
              >
                <v-icon
                  class="mt-n3"
                  size="60"
                  :color="fileIcon(item.description).color"
                  >{{ fileIcon(item.description).icon }}
                </v-icon>
                <v-row
                  justify="center"
                  align="stretch"
                  class="text-center filename mt-2 mb-1 grey--text px-3"
                >
                  {{ item.description || "No file name" }}
                </v-row>
              </a>
            </template>
            <span>Download File</span>
          </v-tooltip>
        </v-row>
      </v-card>

      <v-card
        height="110px"
        width="110px"
        class="my-2 pt-2 mx-5 grey lighten-4"
        flat
      >
        <v-row
          v-if="!fileToUpload"
          justify="center"
          align="stretch"
          style="height: 110px"
        >
          <v-btn
            @click="handleOpenFileBrowser"
            :disabled="fileUploading || demo"
            class="primary mt-10"
            elevation="0"
            icon
          >
            <v-icon :color="orgTheme.buttonIconColor">add</v-icon>
          </v-btn>
        </v-row>
        <input
          type="file"
          style="display: none"
          ref="fileInput"
          @change="handleFilePicked"
        />
        <div v-if="fileToUpload">
          <div justify="center" class="text-center filename mt-2 mb-1 px-3">
            {{ fileToUpload.name }}
          </div>
          <v-row
            v-if="!accept(fileToUpload.name)"
            justify="center"
            class="text-center filename mt-2 mb-1 grey--text px-3"
          >
            File Type Not Supported
            <v-btn
              @click="clearFile"
              small
              color="primary"
              elevation="0"
              class="mt-2"
              >clear</v-btn
            >
          </v-row>
          <v-card-actions v-else>
            <v-row justify="center">
              <v-btn
                class="mt-3"
                small
                color="primary"
                elevation="0"
                :disabled="!fileToUpload || fileUploading"
                @click="handleUploadFile"
              >
                {{ fileUploading ? `${fileUploadingProgress}%` : "Upload" }}
              </v-btn>
            </v-row>
          </v-card-actions>
        </div>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { storage, timestamp } from "@/firebase";

export default {
  props: {},
  data: () => ({
    statuses: [
      { text: "Active", value: "Active" },
      { text: "Inactive", value: "Inactive" },
    ],
    isProcessing: false,
    fileDescription: "",
    fileToUpload: null,
    fileUploading: false,
    fileUploadingProgress: 0,
    fileUploadTask: null,
    permittedFiles: [
      { ext: "doc", icon: "fas fa-file-word", color: "blue" },
      { ext: "docx", icon: "fas fa-file-word", color: "blue" },
      { ext: "xls", icon: "fas fa-file-excel", color: "green" },
      { ext: "xlsx", icon: "fas fa-file-excel", color: "green" },
      { ext: "ppt", icon: "fas fa-file-powerpoint", color: "orange" },
      { ext: "pptx", icon: "fas fa-file-powerpoint", color: "orange" },
      { ext: "pdf", icon: "fas fa-file-pdf", color: "red" },
      { ext: "jpg", icon: "fas fa-file-image", color: "purple" },
      { ext: "jpeg", icon: "fas fa-file-image", color: "purple" },
      { ext: "png", icon: "fas fa-file-image", color: "purple" },
    ],
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    programId() {
      return this.$store.getters.programId;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
    memberTags() {
      return this.$store.state.membertag.memberTags;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    resources: {
      get() {
        return this.currentOffer.resources || [];
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", {
          resources: value,
        });
      },
    },
  },

  methods: {
    clearFile() {
      this.fileToUpload = null;
      this.fileDescription = "";
    },
    handleOpenFileBrowser() {
      this.$refs.fileInput.click();
    },
    handleFilePicked(e) {
      const file = e.target.files[0];
      if (!file) {
        return;
      }
      this.fileToUpload = file;
    },
    handleUploadFile() {
      if (!this.fileToUpload) {
        return;
      }
      this.fileDescription = this.fileToUpload.name;
      this.fileUploadTask = storage
        .ref(
          `uploads/${this.programId}/offerResources/${timestamp}_${this.fileToUpload.name}`
        )
        .put(this.fileToUpload);
      this.fileUploadingProgress = 0;
      this.fileUploading = true;
    },
    handleFinishUpload(url) {
      this.fileUploading = false;
      this.fileUploadingProgress = 0;
      this.fileUploadTask = null;
      this.fileToUpload = null;
      this.resources = [
        ...this.resources,
        {
          id: Math.random(),
          type: "file",
          description: this.fileDescription,
          filename: this.fileDescription,
          url,
          created: new Date(),
        },
      ];
      this.fileToUpload = null;
      this.fileDescription = "";
    },
    handleDeleteResource(resource) {
      this.resources = this.resources.filter((item) => item.id !== resource.id);
    },
    fileIcon(filename) {
      if (filename) {
        const ext = filename.split(".").pop();
        let arr = this.permittedFiles;
        let obj = arr.find((el) => el.ext.toLowerCase() === ext.toLowerCase());
        if (obj) return obj;
        else {
          return { icon: "fas fa-file", color: "grey" };
        }
      } else {
        return { icon: "fas fa-file", color: "grey" };
      }
    },
    accept(filename) {
      if (filename) {
        const ext = filename.split(".").pop();
        let arr = this.permittedFiles;
        let obj = arr.find((el) => el.ext.toLowerCase() === ext.toLowerCase());
        if (obj) return true;
        else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    fileUploadTask: function () {
      if (!this.fileUploadTask) {
        return;
      }

      this.fileUploadTask.on(
        "state_changed",
        (sp) => {
          if (sp.totalBytes <= 0) {
            return;
          }
          this.fileUploadingProgress = Math.floor(
            (sp.bytesTransferred / sp.totalBytes) * 100
          );
        },
        () => {
          this.fileUploading = false;
        },
        () => {
          this.fileUploadTask.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              this.handleFinishUpload(downloadURL);
            });
        }
      );
    },
  },
};
</script>

<style>
.v-table {
  table-layout: fixed;
}
.icon-link {
  text-decoration: none;
}
</style>
