/* eslint-disable no-console */
<template>
  <div class="px-4 pt-4">
    <v-alert
      v-if="pendingMembers.length"
      color="primary"
      border="left"
      dense
      text
    >
      <v-row align="center" no-gutters>
        <v-col cols="auto">
          <v-icon color="primary" class="mx-2" size="26">account_circle</v-icon>
        </v-col>
        <v-col class="pl-1 incentable-alert">
          <div v-if="pendingMembers.length === 1">
            There is {{ pendingMembers.length }} pending member
          </div>
          <div v-else>
            There are {{ pendingMembers.length }} pending members
          </div>
        </v-col>
        <v-col cols="auto">
          <v-btn
            elevation="0"
            color="primary"
            outlined
            @click="handleInvite('Pending')"
            :loading="loading"
            :disabled="loading || demo"
            v-if="isRoleSatisfied('Editor')"
          >
            Send Member Email Invitations
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-row no-gutters align="center">
      <v-col sm="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field>
      </v-col>

      <v-col sm="3" v-if="options.filter">
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter By Status"
          prepend-inner-icon="filter_list"
          class="mr-4"
          multiple
          :items="statusList"
          v-model="filteredStatus"
        ></v-select>
      </v-col>

      <v-col>
        <v-btn
          @click="handleOpenImportDialog()"
          v-if="options.upload"
          class="primary--text"
          text
          :disabled="demo"
          >Upload</v-btn
        >
        <v-btn
          v-if="options.download"
          class="primary--text"
          text
          :href="membersCsv"
          :download="getFilename()"
          :disabled="demo"
          >Download</v-btn
        >
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
      </v-col>

      <v-col sm="auto">
        <v-btn @click="handleNew()" color="primary" elevation="0" class="mt-1">
          create new member
        </v-btn>
      </v-col>
    </v-row>

    <!-- Data table -->

    <v-data-table
      :headers="headers"
      :items="filteredMembers"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              :disabled="demo"
              link
              v-for="nextStatus in getNextStatus(item.status)"
              :key="nextStatus.value"
              @click="handleConfirmNextStatus(item, nextStatus.value)"
            >
              <v-icon class="mr-2" :disabled="demo">send</v-icon>
              <v-list-item-title>{{ nextStatus.label }} </v-list-item-title>
            </v-list-item>

            <v-list-item link :disabled="!isRoleSatisfied('Editor')">
              <v-icon class="mr-2">edit</v-icon>
              <v-list-item-title @click="handleEdit(item)"
                >Edit
              </v-list-item-title>
            </v-list-item>

            <v-list-item link :disabled="!isRoleSatisfied('Editor')">
              <v-icon class="mr-2">delete</v-icon>
              <v-list-item-title @click="handleDeleteConfirmation(item)"
                >Delete
              </v-list-item-title>
            </v-list-item>

            <template>
              <div>
                <v-divider></v-divider>
                <v-list-item
                  class="mt-1"
                  link
                  :disabled="!isRoleSatisfied('Editor')"
                >
                  <v-icon class="mr-2" color="primary">open_in_new</v-icon>
                  <v-list-item-title
                    class="primary--text"
                    @click="openWebsite(item)"
                    >Open In Member Website
                  </v-list-item-title>
                </v-list-item>
              </div>
            </template>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | date }}
      </template>

      <template v-slot:[`item.balance`]="{ item }">
        <span
          class="table-link link"
          @click="handleOpenMemberPointsTable(item.id)"
          >{{ item.balance | number }}</span
        >
      </template>

      <template v-slot:[`item.activated`]="{ item }">
        <div v-if="item.activated">{{ item.activated | date }}</div>
      </template>

      <template v-slot:[`item.activeMemberTags`]="{ item }">
        <v-chip-group column>
          <v-chip small v-for="id in item.tags" :key="id.id" class="ma-1">
            {{ getMemberTagTitle(id) }}
          </v-chip>
        </v-chip-group>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <status-icon :status="item.status" class="pr-3"></status-icon>
        {{ item.status }}
      </template>

      <template v-slot:[`item.avatar`]="{ item }">
        <v-row no-gutters justfy="start" align="center">
          <v-col
            cols="auto"
            v-if="isRoleSatisfied('Editor')"
            @click="handleEdit(item)"
            class="primary--text"
            style="cursor: pointer"
          >
            <v-avatar
              v-if="item.image.smallUrl || item.externalImageUrl"
              size="38"
              class="mr-2"
            >
              <v-img
                :src="
                  item.image.smallUrl
                    ? item.image.smallUrl
                    : item.externalImageUrl
                "
                :lazy-src="
                  item.image.smallUrl
                    ? item.image.smallUrl
                    : item.externalImageUrl
                "
              ></v-img>
            </v-avatar>
            <v-avatar
              v-else
              size="38"
              :color="item.color || getRandomColor()"
              class="mr-2"
            >
              <span class="white--text">{{ item.initials }}</span>
            </v-avatar>
          </v-col>
          <v-col v-else cols="auto" justify="start">
            <v-avatar
              size="38"
              :color="item.color || getRandomColor()"
              class="mr-2"
            >
              {{ item.initials }}
            </v-avatar>
            <span class="float-left table-link">{{ item.fullname }}</span>
          </v-col>
          <v-col
            v-if="isRoleSatisfied('Editor')"
            @click="handleEdit(item)"
            class="table-link"
            style="cursor: pointer"
          >
            {{ item.fullname }}
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.fullname`]="{ item }">
        <div
          v-if="isRoleSatisfied('Editor')"
          @click="handleEdit(item)"
          class="table-link"
          style="cursor: pointer"
        >
          {{ item.fullname }}
        </div>
      </template>
    </v-data-table>

    <!-- Delete dialog -->

    <v-dialog v-model="dialogDeleteOpen" max-width="400px" persistent>
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay absolute color="red" :opacity="systemTheme.cardOpacity">
          </v-overlay>
        </v-img>

        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>

        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading px-16">
            {{
              awardedPoints
                ? "Cannot delete member as points have been awarded"
                : "Are You Sure?"
            }}
          </v-card-title>
        </v-row>

        <v-card-text v-if="!awardedPoints" class="px-16 pb-6">
          <v-row dense>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ fullname }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleCloseDeleteDialog()">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo || awardedPoints"
            >Delete Member</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm Status Change dialog -->

    <v-dialog v-model="statusChangingMember" max-width="400px" persistent>
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
          <v-overlay
            absolute
            color="primary"
            :opacity="systemTheme.cardOpacity"
          >
          </v-overlay>
        </v-img>

        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar size="100" color="white" outline>
            <v-icon size="70" color="primary">send</v-icon>
          </v-avatar>
        </v-row>

        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading"> Are You Sure? </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          <v-row dense>
            <v-col align="center">
              You are about to email an invitation to
              <span class="incentable-form-bold">{{ fullname }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleCloseStatusDialog()">Cancel</v-btn>
          <v-btn
            color="primary"
            elevation="0"
            @click="handleNextStatus()"
            :loading="loading"
            >Comfirm Invitation</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Transations -->
    <member-points-table
      :memberId="transactionsMemberId"
      :open="openMemberPointsTable"
      @onClose="handleCloseMemberPointsTable"
    />

    <!-- New or Edit dialog -->
    <member-add-or-edit
      :memberId="memberId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <!-- Import dialog -->
    <members-import
      :open="dialogImportOpen"
      @onClose="dialogImportOpen = false"
    />
  </div>
</template>

<script>
import { MEMBER_STATUS } from "@/constants/member";
import { AVATAR_COLORS } from "@/constants/colors";
import MemberPointsTable from "../RedeemablePoints/MemberForm/MemberPointsTable.vue";
export default {
  components: {
    MemberPointsTable,
  },
  data: () => ({
    // Component settings unique to this component
    openMemberPointsTable: false,
    dialogImportOpen: false,
    search: "",
    fullname: "",
    avatar: {},
    memberId: "",
    transactionsMemberId: "",
    color: "",
    statusList: MEMBER_STATUS,
    filteredStatus: [],
    statusChangingMember: null,
    member: "",

    // Table columns
    headers: [
      { text: "Status", value: "status", align: "left" },
      { text: "Member", value: "avatar", align: "left" },
      { text: "Email", value: "email", align: "left" },
      { text: "Points", value: "balance", align: "right", sortable: false },
      { text: "Member Tag(s)", value: "activeMemberTags", align: "left" },
      { text: "Data Key", value: "accountKey", align: "left" },
      { text: "Created", value: "created", align: "left" },
      { text: "Activated", value: "activated", align: "left" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],

    // Component settings common to all components
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,

    // Table settings
    options: {
      sortBy: ["title"],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: true,
      download: true,
      more: false,
    },
  }),
  computed: {
    // Functions specific to this component
    members() {
      return this.$store.state.member.members;
    },
    memberTagTitleMap() {
      return this.$store.state.membertag.memberTags.reduce((result, item) => {
        return {
          ...result,
          [item.id]: item.tag,
        };
      }, {});
    },
    filteredMembers() {
      return this.filteredStatus.length
        ? this.members.filter((m) => this.filteredStatus.includes(m.status))
        : this.members;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },

    // Status counts
    pendingMembers() {
      return this.members.filter((m) => m.status === "Pending");
    },

    currentUserRole() {
      return this.$store.getters.currentUserRole;
    },

    // For CSV download file
    memberTagsMap() {
      return this.$store.getters.memberTagsMap;
    },
    companiesMap() {
      return this.$store.getters.companiesMap;
    },
    membersCsv() {
      const dataHeader = "data:text/csv;charset=utf-8,";
      const csvHeader =
        "First Name,Last Name,Email,Account Key,Status,Companies,Member Tags,Awarded,Redeemed,Balance,Created,Updated,Profile Image URL";
      const csvBody = this.members
        .map((member) => {
          const {
            firstname,
            lastname,
            email,
            accountKey,
            status,
            companies,
            tags,
            awarded,
            redeemed,
            balance,
            created,
            updated,
            externalImageUrl,
          } = member;
          const image = '"' + encodeURIComponent(externalImageUrl) + '"';
          const tagsTxt = tags.map((id) => this.memberTagsMap[id]).join(";");
          const companiesTxt = companies
            .map((id) => this.companiesMap[id])
            .join(";");
          return `${firstname},${lastname},${email},${accountKey},${status},${companiesTxt},${tagsTxt},${awarded},${redeemed},${balance},${created},${updated},${image}`;
        })
        .join("\r\n");

      return `${dataHeader}${csvHeader}\r\n${csvBody}`;
    },

    // Functions common to all components
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    awardedPoints() {
      var member = this.members.find((el) => el.id === this.memberId);
      if (member) {
        return member.awarded > 0 ? true : false;
      }
      return false;
    },
  },

  created() {},

  methods: {
    handleOpenMemberPointsTable(id) {
      this.$store.dispatch("loadTransactions", id);
      this.transactionsMemberId = id;
      this.openMemberPointsTable = true;
    },
    handleCloseMemberPointsTable() {
      this.openMemberPointsTable = false;
      this.transactionsMemberId = "";
    },
    getRandomColor() {
      const colors = AVATAR_COLORS;
      const random = Math.floor(Math.random() * colors.length);
      const result = colors[random];
      return result;
    },
    getMemberTagTitle(id) {
      return this.memberTagTitleMap[id];
    },
    openWebsite(item) {
      this.id = item.id;
      this.$store.dispatch("openWeb", this.id);
    },
    handleNew() {
      this.memberId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // console.log(item)
      this.memberId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.memberId = "";
      this.dialogNewOrEditOpen = false;
    },
    handleOpenImportDialog() {
      // Open Import Dialog
      this.dialogImportOpen = true;
    },
    handleDeleteConfirmation(item) {
      // Delete record
      this.memberId = item.id;
      this.fullname = item.fullname;
      this.dialogDeleteOpen = true;
    },
    handleDelete() {
      // Delete record
      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("deleteMember", this.memberId)
        .then(() => (this.dialogDeleteOpen = false))
        .catch(() => (this.dialogDeleteOpen = false));
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.memberId = "";
      this.dialogDeleteOpen = false;
    },
    getNextStatus(currentStatus) {
      if (currentStatus === "Pending") {
        return [{ label: "Invite", value: "Invited" }];
      } else if (currentStatus === "Awaiting Approval") {
        return [{ label: "Approve", value: "Active" }];
      } else if (currentStatus === "Invited") {
        return [{ label: "Re-invite", value: "Invited" }];
      }
      return [];
    },
    getFilename() {
      const program = this.$store.state.program.currentProgram;
      const date = new Date();
      const formattedDate = date.toLocaleDateString(["en-US"], {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      return program.title + " Member List " + formattedDate + ".csv";
    },
    handleConfirmNextStatus(member, nextStatus) {
      this.statusChangingMember = member;
      this.memberId = member.id;
      this.nextStatus = nextStatus;
      this.fullname = member.fullname;
    },
    handleCloseStatusDialog() {
      this.statusChangingMember = null;
      this.memberId = "";
      this.nextStatus = null;
      this.fullname = "";
    },
    handleNextStatus() {
      if (!this.statusChangingMember || !this.nextStatus) {
        return;
      }

      this.$store.dispatch("setLoading", true);

      let action = "";
      if (this.nextStatus === "Invited") {
        action = "inviteMember";
      } else if (this.nextStatus === "Active") {
        action = "approveMember";
      }
      this.$store
        .dispatch(action, this.memberId)
        .then(() => {
          this.$store.dispatch("setLoading", false);
          this.handleCloseStatusDialog();
        })
        .catch(() => {
          this.$store.dispatch("setLoading", false);
          this.handleCloseStatusDialog();
        });
    },
    handleInvite(status) {
      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("inviteByStatus", status)
        .then(() => this.$store.dispatch("setLoading", false));
    },
    sendActivationLink(item) {
      // this is just a test. The activation link needs to be sent upon registration but is not curretly set up
      console.log(item.id);
      this.$store.dispatch("setLoading", true);

      let action = "inviteMember";

      this.$store
        .dispatch(action, item.id)
        .then(() => {
          this.$store.dispatch("setLoading", false);
          this.handleCloseStatusDialog();
        })
        .catch(() => {
          this.$store.dispatch("setLoading", false);
          this.handleCloseStatusDialog();
        });
    },
  },
};
</script>

<style></style>
