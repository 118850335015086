import { db } from "@/firebase";
import { DEMO_PROGRAM } from "@/constants/webtheme/demoprogram";
import { DEMO_USER } from "@/constants/webtheme/demouser";
import _ from "lodash";

const state = {
  user: DEMO_USER,
  sampleProgramId: DEMO_PROGRAM.programId,
  sampleBlogId: DEMO_PROGRAM.blogId,
  // sampleCompanies: DEMO_USER.companies,
  sampleProgressData: [],
  sampleLeaderBoardData: [],
  sampleTableData: [],
  sampleArticleList: {},
  sampleRewardList: {},
  sampleTablesDisplay: [],
  sampleLeaderboardsDisplay: [],
  databucketData: [],
  samplePrimaryMenus: [],
  sampleSecondaryMenus: [],
  sampleRewardCategories: [],
  sampleArticleCategories: [],
  sampleQuizCategories: [],
  sampleOffers: [],
  sampleQuizzes: [],
  samplePages: [],
};

const actions = {
  async loadSampleQuizCategories({ commit }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("quizCategories")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const quizCategories = [];
    querySnapshot.forEach(function (doc) {
      quizCategories.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    commit("setSampleQuizCategories", quizCategories);
    commit("setLoadingTable", false);
  },

  async loadSampleRewardCategories({ commit }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("rewardCategories")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const rewardCategories = [];
    querySnapshot.forEach(function (doc) {
      rewardCategories.push({
        id: doc.id,
        title: doc.data().title,
      });
    });

    commit("setSampleRewardCategories", rewardCategories);
    commit("setLoadingTable", false);
  },

  async loadSampleArticleCategories({ commit }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("categorys")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const articleCategories = [];
    querySnapshot.forEach(function (doc) {
      articleCategories.push({
        id: doc.id,
        title: doc.data().title,
      });
    });
    // console.log(articleCategories)
    commit("setSampleArticleCategories", articleCategories);
    commit("setLoadingTable", false);
  },

  async loadSamplePrimaryMenus({ commit }) {
    commit("setLoadingPrimaryMenus", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("primaryMenus")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const primaryMenus = [];
    querySnapshot.forEach(function (doc) {
      primaryMenus.push({
        id: doc.id,
        title: doc.data().title,
        titleUppercase: doc.data().titleUppercase,
        status: doc.data().status,
        iconFile: doc.data().iconFile,
        highlight: doc.data().highlight,
        order: doc.data().order,
        contentType: doc.data().contentType,
        content: doc.data().content,
        created: doc.data().created,
        updated: doc.data().updated,
      });
    });

    // Note: Firebase 's orderby doesn't send the results if order by field doesn't exist
    const sorted = _.sortBy(primaryMenus, (item) => item.order);
    commit("setSamplePrimaryMenus", sorted);
    commit("setLoadingPrimaryMenus", false);
  },

  async loadSampleSecondaryMenus({ commit }) {
    commit("setLoadingSecondaryMenus", true);
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("menus")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const secondaryMenus = [];
    querySnapshot.forEach(function (doc) {
      secondaryMenus.push({
        id: doc.id,
        title: doc.data().title,
        titleUppercase: doc.data().titleUppercase,
        status: doc.data().status,
        order: doc.data().order,
        iconFile: doc.data().iconFile,
        contentType: doc.data().contentType,
        content: doc.data().content,
        created: doc.data().created,
        updated: doc.data().updated,
      });
    });

    // Note: Firebase 's orderby doesn't send the results if order by field doesn't exist
    const sorted = _.sortBy(secondaryMenus, (item) => item.order);
    commit("setSampleSecondaryMenus", sorted);
    commit("setLoadingSecondaryMenus", false);
  },

  async getSampleProgressData({ commit, state }) {
    const dbRef = db.collection("programs").doc(state.sampleProgramId);
    const leaderboardUnsubscribe = dbRef
      .collection("leaderboards")
      .orderBy("order")
      .onSnapshot(
        (querySnapshot) => {
          var leaderBoardDataArr = [];
          let currentUserLeaderboardArr = [];
          querySnapshot.forEach((doc) => {
            let leaderBoardData = {
              ...doc.data(),
              tableId: doc.id,
            };
            if (
              leaderBoardData.status === "Active" ||
              leaderBoardData.status === "active"
            ) {
              let memberTagExist = false;
              if (leaderBoardData.memberTags.length === 0) {
                memberTagExist = true;
              } else {
                memberTagExist = leaderBoardData.memberTags.some((tag) => {
                  return state.user.tags.includes(tag);
                });
              }
              let companyTagExist = false;
              if (leaderBoardData.companyTags.length === 0) {
                companyTagExist = true;
              } else {
                companyTagExist = leaderBoardData.companyTags.some((tag) => {
                  return state.user.companyTags.includes(tag);
                });
              }
              if (memberTagExist && companyTagExist) {
                const rankMap =
                  leaderBoardData.columnMappings.find(
                    (item) => item.column === "Rank"
                  ) || {};
                let updated = false;
                if (leaderBoardData.participantType === "member") {
                  leaderBoardData.columnData.map((leaderboardObj) => {
                    if (leaderboardObj.id === state.user._id) {
                      currentUserLeaderboardArr.push({
                        ...leaderboardObj,
                        participantType: leaderBoardData.participantType,
                        title: leaderBoardData.title,
                        avatar: leaderBoardData.avatar,
                        displayTitle: leaderBoardData.displayTitle,
                        order: leaderBoardData.order,
                        prefix:
                          leaderBoardData.homeMappings &&
                          leaderBoardData.homeMappings.prefix,
                        suffix:
                          leaderBoardData.homeMappings &&
                          leaderBoardData.homeMappings.suffix,
                        cardHeader: leaderBoardData.headerMappings,
                        headerData:
                          leaderBoardData.headerData.find(
                            (el) => el.id === leaderboardObj.id
                          ) || {},
                        resultsHeader: leaderBoardData.columnMappings,
                        tableId: leaderBoardData.tableId,
                        cardType: "leaderboard",
                        rankValue: leaderboardObj.values[rankMap.uid],
                        iconToRender: leaderBoardData.iconMappings || {},
                        data: leaderBoardData.columnData,
                        remarkData:
                          leaderBoardData.remarkData.find(
                            (el) => el.id === leaderboardObj.id
                          ) || {},
                        remarkMappings: leaderBoardData.remarkMappings,
                      });
                      updated = true;
                    }
                  });
                } else {
                  leaderBoardData.columnData.map((leaderboardObj) => {
                    state.user.companies.map((companyId) => {
                      if (leaderboardObj.id === companyId) {
                        currentUserLeaderboardArr.push({
                          ...leaderboardObj,
                          participantType: leaderBoardData.participantType,
                          title: leaderBoardData.title,
                          displayTitle: leaderBoardData.displayTitle,
                          order: leaderBoardData.order,
                          prefix:
                            leaderBoardData.homeMappings &&
                            leaderBoardData.homeMappings.prefix,
                          suffix:
                            leaderBoardData.homeMappings &&
                            leaderBoardData.homeMappings.suffix,
                          cardHeader: leaderBoardData.headerMappings,
                          headerData:
                            leaderBoardData.headerData.find(
                              (el) => el.id === leaderboardObj.id
                            ) || {},
                          resultsHeader: leaderBoardData.columnMappings,
                          tableId: leaderBoardData.tableId,
                          cardType: "leaderboard",
                          rankValue: leaderboardObj.values[rankMap.uid],
                          iconToRender: leaderBoardData.iconMappings || {},
                          data: leaderBoardData.columnData,
                          remarkData:
                            leaderBoardData.remarkData.find(
                              (el) => el.id === leaderboardObj.id
                            ) || {},
                          remarkMappings: leaderBoardData.remarkMappings || {},
                        });
                        updated = true;
                      }
                    });
                  });
                }
                if (!updated) {
                  currentUserLeaderboardArr.push({
                    participantType: leaderBoardData.participantType,
                    title: leaderBoardData.title,
                    avatar: leaderBoardData.avatar || {},
                    displayTitle: leaderBoardData.displayTitle,
                    order: leaderBoardData.order,
                    prefix:
                      leaderBoardData.homeMappings &&
                      leaderBoardData.homeMappings.prefix,
                    suffix:
                      leaderBoardData.homeMappings &&
                      leaderBoardData.homeMappings.suffix,
                    cardHeader: leaderBoardData.headerMappings,
                    headerData: null,
                    resultsHeader: leaderBoardData.columnMappings,
                    tableId: leaderBoardData.tableId,
                    cardType: "leaderboard",
                    rankValue: null,
                    iconToRender: leaderBoardData.iconMappings || {},
                    data: leaderBoardData.columnData,
                    remarkData: {},
                    remarkMappings: leaderBoardData.remarkMappings,
                  });
                }
              }
              leaderBoardDataArr.push(leaderBoardData);
            }
          });
          // console.log(currentUserLeaderboardArr)
          commit("setSampleLeaderBoardData", currentUserLeaderboardArr);
        },
        (error) => {
          console.log(error);
          if (leaderboardUnsubscribe) {
            leaderboardUnsubscribe();
          }
        }
      );

    const tableUnsubscribe = dbRef
      .collection("tables")
      .orderBy("order")
      .onSnapshot(
        (querySnapshot) => {
          let currentUserResultData = [];
          var resultDataArray = [];
          querySnapshot.forEach((doc) => {
            let resultData = {
              ...doc.data(),
              tableId: doc.id,
            };
            if (
              resultData.status === "Active" ||
              resultData.status === "active"
            ) {
              let memberTagExist = false;
              if (resultData.memberTags.length === 0) {
                memberTagExist = true;
              } else {
                memberTagExist = resultData.memberTags.some((tag) => {
                  return state.user.tags.includes(tag);
                });
              }
              let companyTagExist = false;
              if (resultData.companyTags.length === 0) {
                companyTagExist = true;
              } else {
                companyTagExist = resultData.companyTags.some((tag) => {
                  return state.user.companyTags.includes(tag);
                });
              }
              if (memberTagExist && companyTagExist) {
                if (resultData.participantType === "member") {
                  resultData.tableData.map((resultObj) => {
                    if (resultObj.companyId === state.user._id) {
                      currentUserResultData.push({
                        ...resultObj,
                        title: resultData.title,
                        avatar: resultData.avatar,
                        transactionView: resultData.transactionView,
                        displayTitle: resultData.displayTitle,
                        order: resultData.order,
                        prefix: resultData.homeCard.prefix,
                        suffix: resultData.homeCard.suffix,
                        cardHeader: resultData.cardHeader,
                        resultsHeader: resultData.resultsHeader,
                        tableId: resultData.tableId,
                      });
                    }
                  });
                } else {
                  state.user.companies.map((company) => {
                    resultData.tableData.map((resultObj) => {
                      if (resultObj.companyId === company) {
                        currentUserResultData.push({
                          ...resultObj,
                          title: resultData.title,
                          transactionView: resultData.transactionView,
                          displayTitle: resultData.displayTitle,
                          order: resultData.order,
                          prefix: resultData.homeCard.prefix,
                          suffix: resultData.homeCard.suffix,
                          cardHeader: resultData.cardHeader,
                          resultsHeader: resultData.resultsHeader,
                          tableId: resultData.tableId,
                        });
                      }
                    });
                  });
                }
              }
              resultDataArray.push(resultData);
            }
          });
          // console.log('Array', currentUserResultData)
          commit("setSampleTableData", currentUserResultData);
        },
        (error) => {
          console.log(error);
          if (tableUnsubscribe) {
            tableUnsubscribe();
          }
        }
      );
  },

  async getSamplePages({ commit, state }) {
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("pages")
        .where("status", "==", "Active")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const pages = [];
    querySnapshot.forEach(function (doc) {
      pages.push({
        id: doc.id,
        title: doc.data().title,
        subheading: doc.data().subheading || "",
        body: doc.data().body || "",
        resources: doc.data().resources || [],
      });
    });

    commit("setSamplePages", pages);
  },

  async getSampleOffers({ commit, state }) {
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("offers")
        .where("status", "==", "Active")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const offers = [];
    querySnapshot.forEach(function (doc) {
      offers.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
        cardType: "offer",
      });
    });
    console.log(offers);
    commit("setSampleOffers", offers);
  },

  async getSampleQuizzes({ commit, state }) {
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("quizzes")
        .where("status", "==", "Active")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const quizzes = [];
    querySnapshot.forEach(function (doc) {
      quizzes.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
        cardType: "quiz",
        pointsOffered: doc.data().passPoints,
      });
    });
    commit("setSampleQuizzes", quizzes);
  },

  async getSampleArticleList({ commit, state }) {
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("articles")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const articles = [];
    querySnapshot.forEach(function (doc) {
      articles.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
      });
    });

    commit("setSampleArticleList", articles);
  },

  async getSampleRewardList({ commit, state }) {
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(state.sampleProgramId)
        .collection("rewards")
        .where("status", "==", "Active")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const rewards = [];
    querySnapshot.forEach(function (doc) {
      rewards.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
      });
    });
    commit("setSampleRewardList", rewards);
  },

  async getDatabucketData({ commit, getters }, databucketId) {
    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(databucketId);

    const actualDataRef = databucketRef.collection("actualData");

    const apiCalls = [actualDataRef.orderBy("created").get()];

    let actualDataSnapshot;

    try {
      [actualDataSnapshot] = await Promise.all(apiCalls);
    } catch (e) {
      throw "Error occured when loading data bucket";
    }

    const actualData = [];
    actualDataSnapshot.forEach((item) => {
      const data = item.data();
      actualData.push({
        id: item.id,
        created: data.created.toDate(),
        ...data.data,
      });
    });
    commit("setDatabucketData", actualData);
  },
};

const mutations = {
  setDatabucketData(state, payload) {
    state.databucketData = payload;
  },
  setSampleProgressData(state, payload) {
    state.sampleProgressData = payload;
  },
  setSampleLeaderBoardData(state, payload) {
    state.sampleLeaderBoardData = payload;
  },
  setSampleTableData(state, payload) {
    state.sampleTableData = payload;
  },
  setSampleArticleList(state, payload) {
    state.sampleArticleList = payload;
  },
  setSampleRewardList(state, payload) {
    state.sampleRewardList = payload;
  },
  setSamplePrimaryMenus(state, payload) {
    state.samplePrimaryMenus = payload;
  },
  setSampleSecondaryMenus(state, payload) {
    state.sampleSecondaryMenus = payload;
  },
  setSampleRewardCategories(state, payload) {
    state.sampleRewardCategories = payload;
  },
  setSampleQuizCategories(state, payload) {
    state.sampleQuizCategories = payload;
  },
  setSampleArticleCategories(state, payload) {
    state.sampleArticleCategories = payload;
  },
  setSampleOffers(state, payload) {
    state.sampleOffers = payload;
  },
  setSampleQuizzes(state, payload) {
    state.sampleQuizzes = payload;
  },
  setSamplePages(state, payload) {
    state.samplePages = payload;
  },
};

const getters = {
  databucketData(state) {
    return state.databucketData;
  },
  sampleProgressData(state) {
    return state.sampleProgressData;
  },
  sampleLeaderBoardData(state) {
    return state.sampleLeaderBoardData;
  },
  sampleTableData(state) {
    return state.sampleTableData;
  },
  sampleArticleList(state) {
    return state.sampleArticleList;
  },
  sampleRewardList(state) {
    return state.sampleRewardList;
  },
  samplePrimaryMenus(state) {
    return state.samplePrimaryMenus;
  },
  sampleSecondaryMenus(state) {
    return state.sampleSecondaryMenus;
  },
  sampleRewardCategories(state) {
    return state.sampleRewardCategories;
  },
  sampleQuizCategories(state) {
    return state.sampleQuizCategories;
  },
  sampleArticleCategories(state) {
    return state.sampleArticleCategories;
  },
  sampleOffers(state) {
    return state.sampleOffers;
  },
  sampleQuizzes(state) {
    return state.sampleQuizzes;
  },
  samplePages(state) {
    return state.samplePages;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
