<template>
  <div class="ma-3">
    <v-card elevation="0">
      <v-row no-gutters align="center">
        <v-col sm="3">
          <v-text-field
            v-model="search"
            solo-inverted
            flat
            dense
            hide-details
            label="Search"
            prepend-inner-icon="search"
            class="mr-4"
          ></v-text-field>
        </v-col>

        <v-col sm="3" v-if="options.filter">
          <v-select
            solo-inverted
            flat
            dense
            hide-details
            label="Filter"
            prepend-inner-icon="filter_list"
            class="mr-4"
            :items="opportunities"
          ></v-select>
        </v-col>

        <v-col>
          <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
        </v-col>

        <v-col sm="auto">
          <v-btn
            @click="openEditDialog()"
            color="primary"
            elevation="0"
            class="mt-n2 mr-6"
            :disabled="processingOffer"
          >
            <v-icon>add</v-icon>new
          </v-btn>
        </v-col>
      </v-row>

      <!-- Data table -->
      <v-data-table
        class="pr-6 pt-4"
        :headers="headers"
        :items="entries"
        :search="search"
        :options.sync="options"
        :loading="loadingTable"
        no-data-text="No claims yet..."
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
            close-on-content-click
            :disabled="processingOffer"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor')"
                @click="openEditDialog(item)"
              >
                <v-list-item-title>Review Submission </v-list-item-title>
              </v-list-item>
              <v-list-item
                link
                :disabled="!isRoleSatisfied('Editor') || hasOfferPendingUpdates"
                @click="openDelDialog(item)"
              >
                <v-list-item-title>Delete </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:item.submitted="{ item }">
          {{ formatDisplayDate(item.submitted) }}
        </template>

        <template v-slot:item.reviewed="{ item }">
          {{ formatDisplayDate(item.reviewed) }}
        </template>

        <template v-slot:item.opportunity="{ item }">
          {{ opportunityName(item.opportunity) }}
        </template>

        <template v-slot:item.redeemable="{ item }">
          <v-row justify="center">
            <v-checkbox v-model="item.redeemable" readonly></v-checkbox>
          </v-row>
        </template>

        <template v-slot:item.member="{ item }">
          {{
            memberName(item.member) ||
            "Member Deleted" + " (ID:" + item.member + ")"
          }}
        </template>

        <template v-slot:item.company="{ item }">
          {{ companyName(item.company) || "" }}
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <status-icon :status="item.status" class="pr-3"></status-icon>
          {{ item.status }}
        </template>

        <template v-slot:[`item.title`]="{ item }">
          <div
            v-if="isRoleSatisfied('Editor')"
            @click="handleEdit(item)"
            class="primary--text"
            style="cursor: pointer"
          >
            {{ item.title }}
          </div>
          <div v-else>
            {{ item.title }}
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="delDialog" max-width="400px" persistent>
      <v-card>
        <template v-if="!claimHasEntries">
          <v-card-title class="page-heading mt-0 ml-0">
            Are You Sure?
          </v-card-title>
          <v-card-text class="px-16 pb-6 pt-6">
            <v-row dense>
              <v-col align="center">
                You are about to delete the entry by
                <span class="incentable-form-bold"
                  >{{ memberName(selectedItem.member) }}
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </template>
        <template v-else>
          <v-row class="page-heading pt-3 pl-3" no-gutters>
            Sorry, no can do
          </v-row>
          <v-card-text class="px-6 pb-6 pt-6">
            <v-row dense>
              <v-col align="center">
                You cannot delete a claim that has transacted points. You must
                delete the points transactions from the claim before deleting
                the claim.
              </v-col>
            </v-row>
          </v-card-text>
        </template>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="delDialog = false">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDeleteEntry()"
            :loading="loading"
            elevation="0"
            :disabled="demo || claimHasEntries"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <add-or-edit-entry
      :open="editDialog"
      :entryId="entryId"
      @onCloseEditDialog="closeEditDialog"
    ></add-or-edit-entry>
  </div>
</template>

<script>
import moment from "moment";

import AddOrEditEntry from "./AddOrEditEntry";
export default {
  components: { AddOrEditEntry },

  data: () => ({
    search: "",
    dictionary: {
      custom: {
        deadline: {
          required: () => "Deadline is required",
        },
        status: {
          required: () => "Status is required",
        },
        points: {
          required: () => "Points are required",
        },
        submitted: {
          required: () => "Submitted date is required",
        },
      },
    },

    // Table columns
    headers: [
      { text: "Status", value: "status", align: "left" },
      { text: "Member", value: "member", align: "left" },
      { text: "Company", value: "company", align: "left" },
      { text: "Submitted", value: "submitted", align: "right" },
      { text: "Opportunity", value: "opportunity", align: "left" },
      { text: "Reviewed", value: "reviewed", align: "right" },
      { text: "Points Awarded", value: "totalPoints", align: "right" },
      { text: "Redeemable", value: "redeemable", align: "center" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],

    // Table settings
    options: {
      sortBy: ["submitted"],
      sortDesc: [true],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: true,
      upload: true,
      download: true,
      more: false,
    },

    isProcessing: false,
    editDialog: false,
    delDialog: false,
    dateMenu: false,
    submittedMenu: false,
    reviewedMenu: false,
    selectedItem: {
      resources: [],
      history: [],
      submitted: moment().format("YYYY-MM-DD"),
    },
    defaultItem: {
      status: "Pending",
      resources: [],
      history: [],
      redeemable: false,
      submitted: moment().format("YYYY-MM-DD"),
    },
    bonusPoints: 0,
    description: "",
    entryId: "",
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    entries: {
      get() {
        return this.currentOffer.entries || [];
      },
      set(value) {
        this.$store.dispatch("patchCurrentOffer", {
          entries: value,
        });
      },
    },
    claimHasEntries() {
      if (this.selectedItem && this.selectedItem.history.length > 0) {
        return true;
      }
      return false;
    },
    processingOffer() {
      return this.$store.getters.processingOffer;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    opportunities() {
      return (
        this.currentOffer.opportunities.map((opportunity) => {
          return {
            ...opportunity,
            redeemable:
              opportunity.redeemable !== undefined
                ? opportunity.redeemable
                : false,
          };
        }) || []
      );
    },
    companies() {
      return this.$store.state.company.companies;
    },
    members() {
      return this.$store.state.member.members;
    },
    hasOfferPendingUpdates() {
      return this.$store.getters.hasOfferPendingUpdates;
    },
  },

  methods: {
    clearFile() {
      this.fileToUpload = null;
      this.fileDescription = "";
    },
    formatDate(date) {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },

    formatDisplayDate(date) {
      return date ? moment(date).format("ll") : "";
    },
    openDelDialog(item) {
      this.selectedItem = Object.assign({}, item);
      this.delDialog = true;
    },
    closeDelDialog() {
      this.selectedItem = Object.assign({}, this.defaultItem);
      this.delDialog = false;
    },
    handleDeleteEntry() {
      const index = this.entries.findIndex(
        (el) => el.id === this.selectedItem.id
      );
      const value = this.entries.splice(index, 1);
      this.$store.dispatch("deleteEntry", value[0]);
      this.entries = [...this.entries];
      this.closeDelDialog();
    },
    openEditDialog(item) {
      //console.log(item);
      if (item) {
        this.entryId = item.id;
      } else {
        this.entryId = "";
      }
      this.editDialog = true;
    },
    closeEditDialog() {
      this.entryId = "";
      this.editDialog = false;
    },

    opportunityName(id) {
      const opp = this.opportunities.find((el) => el.id === id) || {
        description: "Not found",
      };
      return opp.description;
    },
    memberName(id) {
      const member = this.members.find((member) => member.id === id) || {};
      return member.fullname;
    },
    companyName(id) {
      const company = this.companies.find((company) => company.id === id) || {};
      return company.title;
    },
  },
};
</script>

<style scoped></style>
