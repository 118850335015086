/* eslint-disable no-console */
<template>
  <div class="mt-8 mr-8 mb-8">
    
    <v-row
      no-gutters
      align="center"
      >
      <v-col sm="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field> 
      </v-col>
      
      <v-col
        sm="3"
        v-if="options.filter"
        >
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter"
          prepend-inner-icon="filter_list"
          class="mr-4"
        ></v-select> 
      </v-col>
      
      <v-col>
        <v-btn
          @click="handleOpenImportDialog()"
          v-if="options.upload"
          class="primary--text"
          text
          >Upload</v-btn>
        <v-btn 
          v-if="options.download"
          class="primary--text"
          text
          download="Filename.csv"
          >Download</v-btn> 
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
      </v-col>
      
      <v-col sm="auto">
        <v-btn
          @click="handleNew()"
          color="primary"
          elevation="0"
          class="mt-n2"
          >
          <v-icon>add</v-icon>new
        </v-btn>
      </v-col>

    </v-row>

    <v-data-table
      :headers="headers"
      :items="memberTags"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu
          bottom
          left
          close-on-content-click
        >
      
        <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link>
                  <v-list-item-title @click="handleEdit(item)">
                    Edit
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  :disabled="!isRoleSatisfied('Editor') || getMemberCountForTag(item.id) > 0"
                  >
                  <v-list-item-title 
                    @click="handleDeleteConfirmation(item)"
                    >Delete
                  </v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
        </template>

        <template v-slot:item.members="{ item }">
          <div v-if="getMemberCountForTag(item.id) > 0">
            <v-avatar
              size="25"
              color="grey lighten-2"
            >
            <span>{{ getMemberCountForTag(item.id) }}</span>
            </v-avatar>
          </div>
        </template>

        <template v-slot:item.tag="{ item }">
          <div @click="handleEdit(item)" class="primary--text" style="cursor:pointer">{{ item.tag }}</div>
        </template>
      
      
    </v-data-table>

    <membertag-add-or-edit
      :memberTagId="selectedMemberTagId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

  <!-- Delete dialog -->

    <v-dialog 
      v-model="dialogDeleteOpen"
      max-width="400px"
      persistent
      >
      <v-card>
        <v-img
          :src="require('@/assets/background.png')"
          :height="systemTheme.cardImageHeight"
        >
        <v-overlay
          absolute
          color="red"
          :opacity="systemTheme.cardOpacity"
        >
        </v-overlay>
        </v-img>
        <v-row justify="center" no-gutters class="mt-n12">
          <v-avatar
            size="100"
            color="white"
            outline
          >
          <v-icon size="99" color="red">error</v-icon>
          </v-avatar>
        </v-row>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading">
            Are You Sure?
          </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-6">
          
          <v-row dense>
            <v-col align="center">
              You are about to delete <span class="incentable-form-bold">{{ tag }}</span>
            </v-col>
          </v-row>
        
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="handleCloseDeleteDialog()"
            >Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Confirm Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
  data: () => ({
    // Component settings unique to this component
    tableTitle: 'Member Tags',
    tableSubtitle: 'Create tags to manage content accessible to members',
    tableIcon: 'label',
    search: '',
    tag: '',
    selectedMemberTagId: '',

    // Table columns
    headers: [
      { text: 'Member Tag', value: 'tag', align: 'left' },
      { text: 'Member(s)', value: 'members', align: 'center' },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false }
    ],
    
    // Component settings common to all components
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,

    // Table settings
    options: {
      sortBy: ['title'],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
  }),
  computed: {
    memberTags () {
      return this.$store.state.membertag.memberTags;
    },
    members () {
      return this.$store.state.member.members;
    },
    demo () {
      return this.$store.state.program.currentProgram.demo
    },
    loadingTable () {
    return this.$store.getters.loadingTable
    },
    loading () {
    return this.$store.getters.loading
    },
    orgTheme () {
      return this.$store.getters.orgTheme
    },
    systemTheme () {
      return this.$store.getters.systemTheme
    }
  },

  methods: {
    // Actions specific to this table //

    getMemberCountForTag (tagId) {
      var tags = []
      const companyList = this.members
      companyList.forEach(item =>
        item.tags.forEach(item => 
        { if (item === tagId)
        return tags.push( item )
        }),
      )
      return tags.length
    },

    // Actions common to all tables //

    handleNew() {
      // Open New form
      this.selectedMemberTagId = '';
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      // Open Edit form
      this.selectedMemberTagId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedMemberTagId = '';
      this.dialogNewOrEditOpen = false;
    },
    handleDeleteConfirmation (item) {
      // Delete record
      this.selectedMemberTagId = item.id;
      this.tag = item.tag;
      this.dialogDeleteOpen = true;
    },
    handleDelete() {
      // Delete record
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('deleteMemberTag', this.selectedMemberTagId)
        .then(() => this.dialogDeleteOpen = false)
        .catch(() => this.dialogDeleteOpen = false)
        this.$store.dispatch('setLoading', false)
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedMemberTagId = '';
      this.dialogDeleteOpen = false;
    },
  }
}
</script>

<style>


</style>
