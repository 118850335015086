<template>
  <div>
    <loading-cards v-if="loadingCards" :count="cardCount" />
    <v-row v-if="!loadingCards" no-gutters class="mt-3">
      <v-col
        v-for="(program, id) in filteredPrograms"
        :key="id"
        class="pa-3"
        md="4"
        xs="12"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card class="el rounded-card">
              <v-img
                v-if="program.webTheme.previewImage"
                :src="
                  require('@/assets/themes/' + program.webTheme.previewImage)
                "
                contain
              >
              </v-img>
              <v-card-title class="pt-3">
                <div class="incentable-card-title">
                  {{ program.webTheme.title }}
                </div>
              </v-card-title>
              <v-card-subtitle class="pb-3">
                <v-chip
                  v-for="tag in program.webTheme.tags"
                  :key="tag"
                  color="grey lighten-2 grey--text"
                  small
                  class="mr-1 mt-1"
                  >{{ tag }}</v-chip
                >
              </v-card-subtitle>
              <v-fade-transition>
                <v-overlay v-if="hover" absolute opacity="0.5" color="#414141">
                  <v-btn
                    rounded
                    x-large
                    elevation="0"
                    color="primary"
                    @click="openTheme(program.id)"
                  >
                    Live Preview
                  </v-btn>
                  <v-btn
                    rounded
                    x-large
                    v-if="mode === 'selector'"
                    elevation="0"
                    color="primary"
                    @click="selectTheme(program.webTheme)"
                  >
                    Select Theme
                  </v-btn>
                  <v-btn
                    rounded
                    x-large
                    v-if="isIncentableAdmin && mode !== 'selector'"
                    elevation="0"
                    color="primary"
                    @click="onLoadProgram(program.id)"
                  >
                    Edit
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>

    <view-theme :open="theme" :programId="programId" @onClose="closeTheme" />
  </div>
</template>

<script>
import ViewTheme from "./ViewTheme.vue";
export default {
  components: { ViewTheme },
  props: ["search", "programs", "mode", "onSelectTheme"],
  data: () => ({
    cardCount: 6,
    theme: false,
    programId: "",
  }),
  created() {},
  computed: {
    loadingCards() {
      return this.$store.getters.loadingCards;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    webTheme() {
      return this.$store.getters.currentProgram.webTheme;
    },
    filteredPrograms() {
      const arr = this.programs;
      const subset = arr.filter((item) => item.status.includes("Theme"));
      if (this.search.length > 0) {
        var inputLower = this.search.toLowerCase();
        var filtered = subset.filter((item) =>
          item.themeTitle.toLowerCase().includes(inputLower)
        );
        return filtered;
      }
      return subset;
    },
  },
  methods: {
    onLoadProgram(id) {
      this.$store.dispatch("loadCurrentProgram", id);
      this.$store.dispatch("selectProgram", id);
      this.$store.dispatch("getCurrentSubscription", id);
      this.$router.push("/dashboard/" + id);
      // reset the table preview account when program changes
      this.$store.dispatch("setPreviewAccountKey", null);
      // reset design view settings
      this.$store.dispatch("setDesignDrawer", "Content");
      this.$store.dispatch("setSelectedPageTemplate", "Home");
    },
    async openTheme(id) {
      this.$store.dispatch("selectProgram", id);
      await this.$store.dispatch("loadCurrentProgram", id);
      console.log(this.webTheme);
      this.programId = id;
      this.theme = true;
    },
    selectTheme(webTheme) {
      webTheme = {
        ...webTheme,
        tags: [],
      };
      this.$emit("onSelectTheme", webTheme);
    },
    closeTheme() {
      console.log("closeTheme");
      this.$store.dispatch("selectProgram", null);
      this.$store.dispatch("clearCurrentProgram");
      this.programId = "";
      this.theme = false;
    },
  },
};
</script>

<style></style>
