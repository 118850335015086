<template>
  <div>
    <v-navigation-drawer
      app
      v-if="drawer"
      clipped
      class="ps"
      floating
      color="transparent"
    >
      <perfect-scrollbar>
        <div class="mr-3" v-for="(section, s) in sections" :key="s">
          <v-row
            v-if="section.label !== null"
            no-gutters
            class="incentable-subheading ml-6 my-3"
          >
            {{ section.label }}
          </v-row>
          <v-card elevation="0" class="mt-1 mx-3 pt-2 px-2 pb-1 rounded-lg">
            <v-card
              v-for="(item, i) in menus(section.id)"
              :key="i"
              @click="navigateTo(item)"
              elevation="0"
              :class="
                active === item.title
                  ? 'menu-selected mb-1 rounded-lg'
                  : 'menu-tile mb-1  rounded-lg'
              "
              transition="fade-transition"
              @mouseover="handleHovering(item)"
              @mouseleave="handleHovering('')"
            >
              <v-row no-gutters align="center">
                <v-col cols="2" class="ml-n2 mr-3">
                  <v-icon
                    size="22"
                    :class="
                      active === item.title
                        ? 'menu-icon-selected'
                        : hovering === item.title
                        ? 'menu-icon-selected'
                        : 'menu-icon'
                    "
                    >{{ item.icon }}</v-icon
                  >
                </v-col>
                <v-col
                  :class="
                    active === item.title
                      ? 'menu-label-selected'
                      : hovering === item.title
                      ? 'menu-label-selected'
                      : 'menu-label'
                  "
                >
                  {{ item.title }}
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </div>
      </perfect-scrollbar>
    </v-navigation-drawer>
    <!-- End Nav Drawer -->
  </div>
</template>

<script>
import { ROLES_DICT } from "@/constants/roles";
import * as utils from "@/utils";

export default {
  data() {
    const { programId } = this.$store.getters;
    return {
      active: "Dashboard",
      hovering: "",
      sectionsOLD: [
        "main",
        "rewards",
        "advanced",
        "points",
        "content",
        "configuration",
      ],
      sections: [
        { id: "main", label: null },
        { id: "rewards", label: "Rewards" },
        { id: "data", label: "Data" },
        { id: "apps", label: "Apps" },
        { id: "content", label: "Content" },
        { id: "configuration", label: "Configuration" },
      ],
      menuItems: [
        {
          group: "main",
          button: false,
          color: "#e91e63",
          buttonColor: "#e6c5d0",
          iconSize: 22,
          icon: "fa-solid fa-gauge",
          title: "Dashboard",
          link: `/dashboard/${programId}`,
          requiredAuth: ROLES_DICT.observer,
        },
        {
          group: "main",
          button: false,
          color: "#66BB6A",
          buttonColor: "#C8E6C9",
          iconSize: 22,
          icon: "groups",
          title: "Members",
          link: `/members/${programId}`,
          requiredAuth: ROLES_DICT.observer,
        },
        {
          group: "main",
          button: false,
          color: "#FFA726",
          buttonColor: "#FFE0B2",
          iconSize: 22,
          icon: "business",
          title: "Companies",
          link: `/companies/${programId}`,
          requiredAuth: ROLES_DICT.observer,
        },
        {
          group: "main",
          button: false,
          color: "#29B6F6",
          buttonColor: "#B3E5FC",
          iconSize: 22,
          icon: "send",
          divider: true,
          title: "Communicate",
          link: `/communicate/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "rewards",
          iconSize: 22,
          icon: "stars",
          title: "Points",
          link: `/points/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "rewards",
          iconSize: 19,
          icon: "fa-solid fa-gift",
          title: "Rewards",
          link: `/rewards/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "rewards",
          iconSize: 22,
          icon: "shopping_cart",
          title: "Orders",
          divider: true,
          link: `/orders/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "data",
          iconSize: 20,
          icon: "fa-solid fa-database",
          title: "Data Buckets",
          link: `/databuckets/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "apps",
          iconSize: 22,
          icon: "emoji_events",
          title: "Leaderboards",
          link: `/leaderboards/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "apps",
          iconSize: 22,
          icon: "fa fa-bullseye",
          title: "Target Trackers",
          divider: true,
          link: `/tables/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "apps",
          iconSize: 22,
          icon: "fa-solid fa-medal",
          title: "Awards",
          link: `/awards/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "apps",
          iconSize: 22,
          icon: "fa-solid fa-ranking-star",
          title: "Tiers",
          link: `/tiers/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "apps",
          iconSize: 22,
          icon: "receipt_long",
          title: "Claims",
          link: `/offers/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "apps",
          iconSize: 22,
          icon: "fa-solid fa-clipboard-question",
          title: "Quizzes",
          divider: true,
          link: `/quizzes/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "configuration",
          iconSize: 22,
          icon: "palette",
          title: "Design",
          link: `/design/${programId}`,
          requiredAuth: ROLES_DICT.observer,
        },
        {
          group: "content",
          iconSize: 22,
          icon: "menu",
          title: "Menus",
          link: `/menus/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "content",
          iconSize: 22,
          icon: "web",
          title: "Pages",
          link: `/pages/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "content",
          iconSize: 22,
          icon: "article",
          title: "Articles",
          link: `/blog/${programId}`,
          requiredAuth: ROLES_DICT.editor,
        },
        {
          group: "configuration",
          iconSize: 22,
          icon: "translate",
          title: "Translate",
          link: `/translate/${programId}`,
          requiredAuth: ROLES_DICT.observer,
        },
        {
          group: "configuration",
          iconSize: 22,
          icon: "settings",
          title: "Settings",
          link: `/settings/${programId}`,
          requiredAuth: ROLES_DICT.admin,
        },
      ],
    };
  },
  computed: {
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    demo() {
      return this.$store.getters.currentProgram.status === "Demo"
        ? true
        : false;
    },
    drawer() {
      return this.$store.state.drawer;
    },
    currentSubscription() {
      return this.$store.getters.currentSubscription;
    },
    trial() {
      const starts = this.currentProgram.trial.starts.seconds;
      const ends = this.currentProgram.trial.ends.seconds;
      const date = new Date();
      const now = Math.floor(date.getTime() / 1000);
      const isTrial = now > starts && now < ends;
      return isTrial;
    },
    expired() {
      if (this.trial) {
        return false;
      }
      return this.currentSubscription.status === "active" ||
        this.isIncentableAdmin
        ? false
        : true;
    },
    navmenu() {
      return this.$store.state.navmenu;
    },
    programId() {
      return this.$store.getters.programId;
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    currentUserRole() {
      return this.$store.getters.currentUserRole;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    filteredMenuItems() {
      return this.menuItems.filter((item) => {
        return utils.isAuthReqFulfilled(
          item.requiredAuth,
          this.currentUserRole
        );
      });
    },
  },
  methods: {
    navigateTo(item) {
      if (!this.expired) {
        this.active = item.title;
        document.activeElement.blur();
        this.$router.push(item.link).catch(() => {});
      } else {
        this.$router.push("/").catch(() => {});
      }
    },
    menus(group) {
      return this.filteredMenuItems.filter((el) => {
        return el.group === group;
      });
    },
    handleHovering(item) {
      this.hovering = item.title;
    },
  },
};
</script>

<style scoped>
.ps {
  height: 83.5vh;
}
.menu-label {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #505050 !important;
  padding-left: 10px !important;
  transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
}
.menu-label-hover {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #e91e63 !important;
  padding-left: 10px !important;
  transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
}
.menu-label-selected {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Figtree", sans-serif !important;
  color: #e91e63 !important;
  padding-left: 10px !important;
  transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
}
.menu-icon {
  color: #505050 !important;
  padding-left: 10px !important;
  transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
}
.menu-icon-hover {
  color: #e91e63 !important;
  padding-left: 10px !important;
  transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
}
.menu-icon-selected {
  color: #e91e63 !important;
  padding-left: 10px !important;
  transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
}
.menu-tile {
  background-color: #e91e6200 !important;
  transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  padding: 6px;
}
.menu-tile:hover {
  background-color: #fadee7 !important;
  transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  padding: 6px;
}
.menu-selected {
  background-color: #fadee7 !important;
  padding: 6px;
}
</style>
