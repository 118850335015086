<template>
  <v-container v-if="loadingCurrentLeaderboard" fluid fill-height>
    <v-row align="center" justify="center">
      <v-progress-circular
        :size="100"
        :width="8"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-row>
  </v-container>

  <v-container v-else class="page" fluid>
    <v-toolbar dense elevation="0">
      <v-row no-gutters justify="start">
        <v-col cols="12" lg="8">
          <v-tabs v-model="tab" class="mx-n4">
            <v-tab class="tab"> Builder </v-tab>
            <v-tab class="tab"> Winners </v-tab>
            <v-tab class="tab"> Data </v-tab>
            <v-tab class="tab"> Card </v-tab>
            <v-tab class="tab"> Titles </v-tab>
            <v-tab class="tab"> Tags </v-tab>
            <v-tab class="tab"> Other </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-row no-gutters justify="start" class="mt-5">
      <v-col cols="12" lg="8">
        <v-alert
          :value="noDataSource"
          color="primary"
          border="left"
          text
          class="mx-3 incentable-alert"
        >
          <v-icon color="primary" class="mx-2" size="26">warning</v-icon>
          No data source connected. On the Data tab, select a Data Bucket or
          upload a CSV file, then set the Data Key
        </v-alert>
        <v-alert
          :value="invalid"
          color="primary"
          border="left"
          text
          class="mx-3 incentable-alert"
        >
          <v-icon color="primary" class="mx-2" size="26">warning</v-icon>
          Invalid entry... Both <strong>Title</strong> and
          <strong>Display Title</strong> are required
        </v-alert>
        <v-tabs-items v-model="tab">
          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <leaderboard-header />
            <leaderboard-columns />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <leaderboard-winners />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <leaderboard-data />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <leaderboard-card />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <leaderboard-titles />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <leaderboard-tags />
          </v-tab-item>

          <v-tab-item
            transition="fade-transition"
            reverse-transition="fade-transition"
          >
            <leaderboard-other />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <div class="sticky-footer">
      <v-btn
        color="primary"
        :disabled="
          (!hasLeaderboardPendingUpdates && !hasLeaderboardRawPendingUpdates) ||
          demo
        "
        :loading="isProcessing"
        @click="handleSave"
        elevation="0"
      >
        save leaderboard
      </v-btn>
      <span class="incentable-form-subheading ml-3">
        Last Saved {{ currentLeaderboard.updated | date }}
      </span>
    </div>
  </v-container>
</template>

<script>
import LeaderboardTags from "./Components/Tags";
import LeaderboardTitles from "./Components/Titles";
import LeaderboardOther from "./Components/Other";
import LeaderboardWinners from "./Components/Winners";
import LeaderboardCard from "./Components/Card";
import LeaderboardHeader from "./Components/Header";
import LeaderboardColumns from "./Components/Columns";
import LeaderboardData from "./Components/Data";
export default {
  components: {
    LeaderboardTags,
    LeaderboardOther,
    LeaderboardTitles,
    LeaderboardWinners,
    LeaderboardCard,
    LeaderboardHeader,
    LeaderboardColumns,
    LeaderboardData,
  },
  created() {
    const leaderboardId = this.$route.params.leaderboardId;
    this.$store.dispatch("initialize");
    this.$store.dispatch("setNavMenu", true);
    this.$store.dispatch("loadMemberTags");
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadDatabuckets");
    this.$store.dispatch("loadCurrentLeaderboard", leaderboardId);
  },

  data: function () {
    return {
      invalid: false,
      isProcessing: false,
      tab: null,
    };
  },

  computed: {
    noDataSource() {
      if (this.currentLeaderboard.accountKeyColumn) {
        return false;
      }
      return true;
    },

    demo() {
      return this.$store.state.program.currentProgram.demo;
    },

    currentLeaderboard() {
      return this.$store.state.leaderboard.currentLeaderboard;
    },

    loadingCurrentLeaderboard() {
      return this.$store.state.leaderboard.loadingCurrentLeaderboard;
    },

    hasLeaderboardPendingUpdates() {
      return this.$store.getters.hasLeaderboardPendingUpdates;
    },

    hasLeaderboardRawPendingUpdates() {
      return this.$store.getters.hasLeaderboardRawPendingUpdates;
    },
  },

  methods: {
    handleSave() {
      if (
        !this.currentLeaderboard.title ||
        !this.currentLeaderboard.displayTitle
      ) {
        this.invalid = true;
        return;
      }
      this.invalid = false;
      this.isProcessing = true;
      this.$store.dispatch("updateLeaderboard").then(() => {
        this.$store.dispatch("updateResult");
        this.isProcessing = false;
      });
    },
  },
};
</script>

<style scoped>
.sticky-footer {
  position: fixed;
  background: transparent;
  bottom: 0px;
  margin-bottom: 20px;
  margin-left: 30px;
  z-index: 1000 !important;
}
</style>
