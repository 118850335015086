<template>
  <div class="page grey lighten-4">
    <v-card class="el rounded-lg mt-1 mr-6 mb-15">
      <v-tabs>
        <v-tab class="tab"> Members </v-tab>
        <v-tab class="tab"> Tags </v-tab>
        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <members-list></members-list>
        </v-tab-item>
        <v-tab-item
          transition="fade-transition"
          reverse-transition="fade-transition"
        >
          <member-tags-list></member-tags-list>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <app-snackbar></app-snackbar>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadMemberTags");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("setNavMenu", true);
  },
  data() {
    return {
      search: "",
      searchActive: false,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
