<template>
  <div>
    <!-- Card Table-->
    <v-row no-gutters v-if="!loadingCards" class="mt-3 mb-16">
      <v-col
        v-if="status === 'Active'"
        class="pr-6 pb-3 pt-3"
        cols="12"
        xl="4"
        sm="6"
      >
        <v-card
          class="el rounded-card gradient-card-background"
          style="height: 224px"
        >
          <v-row no-gutters justify="end">
            <v-btn
              class="manage-button white--text mr-6"
              elevation="0"
              fab
              color="primary"
              @click="handleAdd()"
            >
              <v-icon :color="orgTheme.buttonIconColor">add</v-icon>
            </v-btn>
          </v-row>

          <v-card-title>
            <div class="incentable-program-card-title" @click="handleAdd()">
              Create a Databucket
            </div>
          </v-card-title>
          <v-card-subtitle class="incentable-card-subtitle white--text"
            >Upload targets and data to power target trackers, leaderboards and
            formulate points</v-card-subtitle
          >
        </v-card>
      </v-col>

      <v-col
        v-if="
          databucketsByStatus.length < 1 && !loadingCards && status !== 'Active'
        "
      >
        <v-row class="mt-16 page-heading" justify="center">
          <v-icon size="60" color="primary">inventory</v-icon>
        </v-row>
        <v-row
          class="mt-8 incentable-page-subheading primary--text"
          justify="center"
        >
          None with the status of <strong class="ml-1">{{ status }}</strong>
        </v-row>
      </v-col>

      <v-col
        v-for="(databucket, id) in databucketsByStatus"
        :key="id"
        class="pr-6 pb-3 pt-3"
        cols="12"
        xl="4"
        sm="6"
      >
        <v-card
          class="el rounded-card gradient-card-background"
          style="height: 224px"
        >
          <v-row no-gutters justify="end">
            <v-btn
              class="manage-button white--text mr-6"
              elevation="0"
              fab
              color="primary"
              @click="handleManage(databucket.id)"
            >
              <v-icon :color="orgTheme.buttonIconColor">arrow_forward</v-icon>
            </v-btn>
          </v-row>
          <v-card-title>
            <div
              class="incentable-program-card-title"
              @click="onLoadDatabucket(databucket)"
            >
              {{ databucket.title }}
            </div>
          </v-card-title>
          <v-card-subtitle class="incentable-card-subtitle white--text">
            Updated {{ databucket.updated | date }}
          </v-card-subtitle>
          <v-row no-gutters justify="start">
            <v-card-actions class="pl-2 card-actions pb-2">
              <v-chip
                v-if="databucket.pointsFormula.length > 0"
                class="capitalize table-subtitle white--text"
                small
                color="transparent"
              >
                <v-icon left size="21" color="green lighten-2">stars</v-icon>
                Points
              </v-chip>
              <v-chip
                v-if="
                  databucket.pointsRedeemable &&
                  databucket.pointsFormula.length > 0
                "
                class="capitalize table-subtitle white--text"
                small
                color="transparent"
              >
                <v-icon left size="21" color="green lighten-2"
                  >shopping_cart_checkout</v-icon
                >
                Redeemable
              </v-chip>
              <v-chip
                v-if="
                  databucket.pointsRedeemable &&
                  databucket.pointsFormula.length === 0
                "
                class="capitalize table-subtitle white--text"
                small
                color="transparent"
              >
                <v-icon left size="21" color="red lighten-2"
                  >remove_shopping_cart</v-icon
                >
                Redeemable (No points set up)
              </v-chip>
            </v-card-actions>
          </v-row>
          <v-row no-gutters justify="end">
            <v-card-actions class="pl-2 card-actions pb-2">
              <v-chip
                class="capitalize table-subtitle white--text"
                small
                color="transparent"
              >
                <v-icon
                  v-if="databucket.status === 'Active'"
                  size="10"
                  class="mr-1 ml-n1"
                  color="green"
                  >circle</v-icon
                >
                <v-icon
                  v-else
                  size="10"
                  class="mr-1 ml-n1"
                  color="red lighten-2"
                  >circle</v-icon
                >
                {{ databucket.status }}
              </v-chip>
            </v-card-actions>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <!-- End Card Table-->

    <databucket-add :open="openAddDialog" @onClose="handleCloseAddDialog" />
  </div>
</template>

<script>
export default {
  props: ["databuckets", "status"],
  created() {},
  data: () => ({
    openAddDialog: false,
  }),
  computed: {
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    programId() {
      return this.$store.getters.programId;
    },
    databucketsByStatus() {
      var array = this.databuckets.filter(
        (item) => item.status === this.status
      );
      return array;
    },
    loadingCards() {
      return this.$store.getters.loadingCards;
    },
  },
  methods: {
    handleAdd() {
      this.openAddDialog = true;
    },
    handleCloseAddDialog() {
      this.openAddDialog = false;
    },
    handleManage(databucketId) {
      this.$router.push(
        `/databuckets/${this.programId}/databucket/${databucketId}`
      );
    },
    toggleHowTo() {
      const val = this.$store.state.howTo;
      if (val == false) {
        this.$store.dispatch("setHowTo", true);
        this.$store.dispatch("setPreview", false);
      } else {
        this.$store.dispatch("setHowTo", false);
      }
    },
  },
};
</script>

<style scoped></style>
