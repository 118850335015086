/* eslint-disable no-console */
<template>

  <div class="mt-8 mr-8 mb-8">

    <v-row
      no-gutters
      justify="space-between"
      align="center"
      cols="12"
      >
      <v-col lg="3">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field> 
      </v-col>
      
      <v-col
        lg="3"
        v-if="options.filter"
        >
        <v-select
          solo-inverted
          dense
          flat
          hide-details
          label="Filter"
          prepend-inner-icon="filter_list"
          class="mr-4"
        ></v-select> 
      </v-col>
      
      <v-col>
        <v-btn v-if="options.upload" class="primary--text" text>Upload</v-btn>
        <v-btn v-if="options.download" class="primary--text" text>Download</v-btn> 
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn> 
      </v-col>
      
    </v-row>

    <v-data-table
      :headers="headers"
      :items="notifications"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      
      <template v-slot:item.type="{ item }">
        <v-icon color="primary">{{ getStatusIcon(item.type).icon }}</v-icon>
        {{ getStatusIcon(item.type).text }}
        
      </template>
      
      <template v-slot:item.heading="{ item }">
        <div @click="viewNotification(item)" class="primary--text" style="cursor:pointer">{{ item.heading }}</div>
      </template>
      
      <template v-slot:[`item.created`]="{ item }">
        {{ item.created | date }}
      </template>

      <template v-slot:item.recipients="{ item }">
        <div @click="viewRecipients(item)" class="primary--text" style="cursor:pointer">{{ item.memberIds.length }}</div>
      </template>
      
    </v-data-table>

    <communication-preview
      v-if="dialogPreview"
      :notification="previewObj"
      :open="dialogPreview"
      @onClose="handleDialogPreviewClose"
    />

    <communication-recipients
      v-if="dialogSentTo"
      :sentTo="sentTo"
      :open="dialogSentTo"
      @onClose="handleDialogSentToClose"
    />

  </div>
</template>

<script>

 export default {
  data: () => ({
    // Component settings
    tableTitle: 'Sent',
    tableSubtitle: 'History of sent emails and notifications',
    tableIcon: 'history',
    search: '',
    dialogSentTo: false,
    dialogPreview: false,
    
    // Table settings
    options: {
      sortBy: ['sent'],
      sortDesc: [true],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
      
    // Table columns
    headers: [
      { text: 'Type', sortable: true, value: 'type', align: 'left' },
      { text: 'Heading', value: 'heading', sortable: true, align: 'left' },
      { text: 'Sent', value: 'created', sortable: true, align: 'left' },
      { text: 'Sent To', value: 'recipients', sortable: false, align: 'left' },
    ],
    pagination: {
      sortBy: 'created', descending: true, rowsPerPage: 5,
    },
    previewObj: {},
    sentTo: {},
  }),
  computed: {
    loadingTable () {
      return this.$store.getters.loadingTable
    },
    notifications () {
      return this.$store.state.notification.notifications;
    },
  },
  created () {
    this.$store.dispatch('setDialogNotificationRecipients', false)
  },
  methods: {
    viewNotification(item) {
      this.dialogPreview = true
      this.previewObj = item;
    },
    viewRecipients(item) {
      this.dialogSentTo = true
      this.$store.dispatch('setLoadingTable', true)
      const array = item.memberEmails;
      const recipients = []
      array.forEach(email => {
        recipients.push({email})
      });
      this.sentTo = recipients
      this.$store.dispatch('setLoadingTable', false)
    },
    handleDialogSentToClose () {
      this.sentTo = {}
      this.dialogSentTo = false
    },
    handleDialogPreviewClose () {
      this.previewObj = {}
      this.dialogPreview = false
    },
    getStatusIcon (item) {
      if (item === 'app') {
        return { icon: 'announcement', text:'App' }
      } else if (item === 'email') {
        return { icon: 'email', text:'Email' }
      }
    },
  }
}
</script>

<style scoped>
.no-padding{
  padding: 0px !important;
  padding-top: 24px !important;
}
</style>
