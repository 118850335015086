var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.drawer)?_c('v-navigation-drawer',{staticClass:"ps",attrs:{"app":"","clipped":"","floating":"","color":"transparent"}},[_c('perfect-scrollbar',_vm._l((_vm.sections),function(section,s){return _c('div',{key:s,staticClass:"mr-3"},[(section.label !== null)?_c('v-row',{staticClass:"incentable-subheading ml-6 my-3",attrs:{"no-gutters":""}},[_vm._v(" "+_vm._s(section.label)+" ")]):_vm._e(),_c('v-card',{staticClass:"mt-1 mx-3 pt-2 px-2 pb-1 rounded-lg",attrs:{"elevation":"0"}},_vm._l((_vm.menus(section.id)),function(item,i){return _c('v-card',{key:i,class:_vm.active === item.title
                ? 'menu-selected mb-1 rounded-lg'
                : 'menu-tile mb-1  rounded-lg',attrs:{"elevation":"0","transition":"fade-transition"},on:{"click":function($event){return _vm.navigateTo(item)},"mouseover":function($event){return _vm.handleHovering(item)},"mouseleave":function($event){return _vm.handleHovering('')}}},[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"ml-n2 mr-3",attrs:{"cols":"2"}},[_c('v-icon',{class:_vm.active === item.title
                      ? 'menu-icon-selected'
                      : _vm.hovering === item.title
                      ? 'menu-icon-selected'
                      : 'menu-icon',attrs:{"size":"22"}},[_vm._v(_vm._s(item.icon))])],1),_c('v-col',{class:_vm.active === item.title
                    ? 'menu-label-selected'
                    : _vm.hovering === item.title
                    ? 'menu-label-selected'
                    : 'menu-label'},[_vm._v(" "+_vm._s(item.title)+" ")])],1)],1)}),1)],1)}),0)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }