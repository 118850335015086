<template>
  <div>
    <v-row justify="center" no-gutters class="mb-5">
      <v-btn-toggle
        v-model="term"
        borderless
        dense
        mandatory
        rounded
        color="primary"
      >
        <v-btn :disabled="loadingProducts" class="mx-0 pl-4" value="Annually"
          >Annually</v-btn
        >
        <v-btn :disabled="loadingProducts" class="mx-0" value="Quarterly"
          >Quarterly</v-btn
        >
        <v-btn :disabled="loadingProducts" class="mx-0 pr-4" value="Monthly"
          >Monthly</v-btn
        >
      </v-btn-toggle>
    </v-row>
    <v-row justify="center" no-gutters class="mb-5">
      <v-btn-toggle
        v-model="currency"
        borderless
        dense
        mandatory
        rounded
        color="primary"
      >
        <v-btn
          v-for="currency in currencies"
          :key="currency"
          :disabled="loadingProducts"
          class="mx-0"
          :value="currency"
          >{{ currency }}</v-btn
        >
      </v-btn-toggle>
    </v-row>
    <v-row v-if="loadingProducts" no-gutters>
      <v-col v-for="card in count" :key="card">
        <v-card class="el mx-3 mb-6 mt-3 pa-6" outlined>
          <v-skeleton-loader type="heading"></v-skeleton-loader>
          <v-skeleton-loader class="mt-6" type="heading"></v-skeleton-loader>
          <v-skeleton-loader class="mt-3" type="text"></v-skeleton-loader>
          <v-skeleton-loader
            class="mt-3 mb-n3"
            type="actions"
          ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else no-gutters>
      <v-col v-for="(product, id) in stripeProducts" :key="id">
        <v-card class="mx-2 mb-6 mt-4 el">
          <v-card-text class="pl-5 pt-1">
            <v-row no-gutters justify="center">
              <v-icon color="primary" size="41" class="mt-6">{{
                iconToShow(product.name)
              }}</v-icon>
            </v-row>
            <v-row no-gutters class="plan-title mt-4 mb-3" justify="center">{{
              product.name
            }}</v-row>
            <div v-if="product.contactSales" class="custom-margin">
              <v-row no-gutters class="price-title">Contact Sales</v-row>
              <v-row no-gutters class="price-subtitle mt-0"
                >For a custom price</v-row
              >
            </div>
            <div v-else>
              <v-row no-gutters justify="center">
                <span class="price-title"
                  >${{ monthlyPrice(product.amount) }}</span
                >
                <span class="custom-margin-2">/month</span>
              </v-row>
              <v-row no-gutters class="mt-1 mb-2" justify="center">
                <span class="price-subtitle"
                  >${{ product.amount | number }}</span
                >
                <span class="price-subtitle ml-1">{{ termLabel() }}</span>
              </v-row>
            </div>
            <v-row
              v-if="!show"
              no-gutters
              @click="show = true"
              class="price-subtitle pointer primary--text"
              justify="center"
            >
              See Inclusions
              <v-icon size="13" color="primary">expand_more</v-icon>
            </v-row>
            <v-row
              v-if="show"
              no-gutters
              @click="show = false"
              class="price-subtitle pointer primary--text"
              justify="center"
            >
              Hide Inclusions
              <v-icon size="13" color="primary">chevron_left</v-icon>
            </v-row>
            <div v-show="show" class="ml-0">
              <v-row no-gutters class="mb-0">
                <v-col>
                  <v-row no-gutters>
                    <span class="features-title">Users</span>
                  </v-row>
                  <ul>
                    <li
                      class="price-subtitle"
                      no-gutters
                      v-for="(feature, id) in featureList(
                        product.name,
                        'users'
                      )"
                      :key="id"
                    >
                      {{ feature.title }}
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-0">
                <v-col>
                  <v-row no-gutters>
                    <span class="features-title">Features</span>
                  </v-row>
                  <ul>
                    <li
                      class="price-subtitle"
                      no-gutters
                      v-for="(feature, id) in featureList(
                        product.name,
                        'features'
                      )"
                      :key="id"
                    >
                      {{ feature.title }}
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-0">
                <v-col>
                  <v-row no-gutters>
                    <span class="features-title">Support</span>
                  </v-row>
                  <ul>
                    <li
                      class="price-subtitle"
                      no-gutters
                      v-for="(feature, id) in featureList(
                        product.name,
                        'support'
                      )"
                      :key="id"
                    >
                      {{ feature.title }}
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions class="pr-4">
            <v-btn
              v-if="product.contactSales"
              @click="handleOpenMessageDialog(product.name)"
              elevation="0"
              block
              color="primary"
              :loading="loading"
              :disabled="!product.amount || loading"
            >
              Contact sales
            </v-btn>
            <v-btn
              v-else
              @click="checkout(product, newProgramData)"
              elevation="0"
              block
              color="primary"
              :loading="loading"
              :disabled="
                !product.amount ||
                loading ||
                (isIncentableAdmin && programId.length > 0)
              "
            >
              Pay ${{ product.amount | number }}
              <span v-if="isIncentableAdmin" class="ml-1">[admin]</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <message-new
      title="Contact Sales"
      subtitle="For a custom price"
      subject="Sales Enquiry From Create Program Page"
      :open="messageDialogOpen"
      :plan="selectedPlan"
      @onClose="handleCloseMessageDialog"
    ></message-new>
  </div>
</template>

<script>
import { PLANS, FEATURES } from "@/constants/features.js";
import MessageNew from "./MessageNew.vue";
import { timestamp } from "@/firebase";
export default {
  components: { MessageNew },
  props: ["newProgramData"],
  data: () => ({
    term: "Annually",
    currency: "usd",
    show: true,
    count: 3,
    messageDialogOpen: false,
    selectedPlan: "",
    testMode: false, // Set to false to see live producs and run live payments.
    // When switching to test mode you must change the restricted key and webhook signing secret in
    // the Firebase extension. Test keys are in https://dashboard.stripe.com/test/webhooks/we_1LJzxYKgrl4M61xGqBwoEorC
    // and https://dashboard.stripe.com/test/apikeys
  }),
  created() {
    this.$store.dispatch("loadProducts");
  },
  computed: {
    loadingProducts() {
      return this.$store.getters.loadingCards;
    },
    loading() {
      return this.$store.getters.loading;
    },
    userEmail() {
      return this.$store.getters.email;
    },
    userId() {
      return this.$store.getters.user.id;
    },
    programId() {
      return this.$store.getters.programId;
    },
    isIncentableAdmin() {
      return this.$store.state.program.isIncentableAdmin;
    },
    currencies() {
      const arr = this.$store.state.plans.products;
      const result = [...new Set(arr.map((el) => el.currency))];
      return result;
    },
    stripeProducts() {
      // Products added to Stripe must have { onWebsite: true } added to the product metadata in Stripe
      // before they will appear to users on the website
      // Agency subscriptions are not shown on the website. Payment links must be used for these.
      const arr = this.$store.state.plans.products;
      let selected = [];
      if (this.term == "Monthly") {
        selected = { interval: "month", intervalCount: 1 };
      } else if (this.term == "Quarterly") {
        selected = { interval: "month", intervalCount: 3 };
      } else if (this.term == "Annually") {
        selected = { interval: "year", intervalCount: 1 };
      }
      const result = arr.filter((el) => {
        const testMode = el.testMode === "true";
        const onWebsite = el.onWebsite === "true";
        return (
          el.interval === selected.interval &&
          el.intervalCount === selected.intervalCount &&
          el.currency === this.currency &&
          testMode === this.testMode &&
          onWebsite === true
        );
      });
      const sorted = result.sort((a, b) => a.order - b.order);
      return sorted;
    },
  },
  methods: {
    iconToShow(plan) {
      if (plan === "Lite") {
        return "fas fa-gifts";
      } else if (plan === "Lite+") {
        return "fas fa-gifts";
      } else if (plan === "Pro") {
        return "fas fa-medal";
      } else if (plan === "Pro+") {
        return "fas fa-medal";
      } else if (plan === "Enterprise") {
        return "fas fa-building";
      } else if (plan === "Enterprise+") {
        return "fas fa-building";
      }
      return "fas fa-medal";
    },
    reload() {
      this.$store.dispatch("loadProducts");
    },
    handleOpenMessageDialog(plan) {
      this.selectedPlan = plan;
      this.messageDialogOpen = true;
    },
    handleCloseMessageDialog() {
      this.selectedPlan = "";
      this.messageDialogOpen = false;
    },
    featureList(name, group) {
      const plan = PLANS.find((el) => el.plan === name);
      const features = plan.features.sort(plan.id);
      const list = [];
      features.forEach((el) => list.push(FEATURES.find((x) => x.id === el)));
      const final = list.filter((x) => x !== undefined && x.group === group);
      return final;
    },
    monthlyPrice(amount) {
      if (this.term === "Annually") {
        return Math.round(amount / 12);
      } else if (this.term === "Quarterly") {
        return Math.round(amount / 3);
      } else if (this.term === "Monthly") {
        return amount;
      } else {
        return "";
      }
    },
    termLabel() {
      const term = "Billed " + this.term;
      return term || null;
    },
    async checkout(product, newProgramData) {
      this.$store.dispatch("setLoading", true);
      if (this.programId) {
        const programData = {
          priceId: product.priceId,
          incentableProgramId: this.programId,
        };
        await this.$store.dispatch(
          "handleCheckoutExistingProgram",
          programData
        );
      } else {
        const date = new Date();
        const ends = new Date(date.setDate(date.getDate() + 14));
        const starts = new Date();
        const programData = {
          priceId: product.priceId,
          webTheme: newProgramData.webTheme,
          title: newProgramData.title,
          displayTitle: newProgramData.title,
          titleUppercase: newProgramData.title.toUpperCase(),
          url: newProgramData.url + ".incentable.com",
          contactPage: {
            body: "Contact us with any questions",
            email: "Add an email in admin settings",
            heading: "Get In Touch",
            name: "Member Support Team",
            phone: "Add a phone number in admin settings",
          },
          points: {
            label: "Points",
          },
          rewards: {
            active: true,
            cart: true,
            preview: true,
          },
          homeBlogs: {
            blogs: [],
            previews: 10,
          },
          homeRewardCatalogues: {
            rewardCatalogues: [],
          },
          demo: false,
          status: "Active",
          createdAt: timestamp,
          trial: {
            starts: this.isIncentableAdmin ? starts : "",
            ends: this.isIncentableAdmin ? ends : "",
          },
          createdBy: this.userId,
          iosAppStatus: "Inactive",
          appleStoreUrl: "",
          androidAppStatus: "Inactive",
          googleStoreUrl: "",
          termsPage: {
            body: "Add your program terms and conditions in the Settings menu",
          },
          signinOpen: false,
          registrationOpen: false,
          addCompanyAtRegistration: false,
          joinCompanyAtRegistration: false,
          multipleCompaniesAtRegistration: false,
          companyRegistrationGroups: [],
          mobileAtRegistration: false,
          mobileAtRegistrationRequired: false,
          memberDataKeyAtRegistration: false,
          memberDataKeyAtRegistrationRequired: false,
          companyDataKeyAtRegistration: false,
          companyDataKeyAtRegistrationRequired: false,
          memberDataKeyLabel: "",
          memberDataKeyDescription: "",
          companyDataKeyLabel: "",
          companyDataKeyDescription: "",
          memberPhotoIdLabel: "",
          memberPhotoIdDescription: "",
          memberPhotoIdAtRegistration: false,
          memberPhotoIdAtRegistrationRequired: false,
          companyAtRegistrationLabel: "",
          companyRegistrationGroupLabel: "",
          translate: {
            locale: "en",
            hide: false,
          },
          admins: [
            {
              adminEmail: this.userEmail,
              adminRole: "Admin",
            },
          ],
        };
        // console.log(programData)
        await this.$store.dispatch("handleCheckoutNewProgram", programData);
      }
    },
    reset() {
      this.$store.dispatch("setLoading", false);
    },
  },
};
</script>

<style scoped>
.price-subtitle {
  font-family: "Montserrat", sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #4e4e4e !important;
}
.price-title {
  font-family: "Montserrat", sans-serif !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  color: #4e4e4e !important;
}
.plan-title {
  font-family: "Montserrat", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #e91e63 !important;
  text-transform: uppercase;
}
.features-title {
  font-family: "Montserrat", sans-serif !important;
  font-size: 11px !important;
  font-weight: 600 !important;
  color: #4e4e4e !important;
  margin-top: 3px !important;
}
.custom-margin {
  margin-bottom: 9px;
}
.custom-margin-2 {
  margin-top: 2px;
}
.cap {
  text-transform: uppercase !important;
}
</style>
