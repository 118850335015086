<template>
  <div class="margin-bottom">
    <v-container v-if="loadingDatabucket" fluid fill-height>
      <v-row align="center">
        <v-progress-circular
          :size="100"
          :width="8"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>

    <v-container v-else class="page" fluid>
      <v-toolbar dense elevation="0">
        <v-row no-gutters justify="start" align="center">
          <v-col>
            <v-tabs v-model="tab" class="mx-n4">
              <v-tab class="tab"> Builder </v-tab>
              <v-tab class="tab"> Data </v-tab>
              <v-tab class="tab"> Targets </v-tab>
              <v-tab class="tab"> Target Points Rules </v-tab>
              <v-tab class="tab"> Settings </v-tab>
            </v-tabs>
          </v-col>
          <v-col>
            <v-row no-gutters align="center" justify="end">
              <v-chip
                v-if="databucket.pointsFormula.length > 0"
                class="capitalize table-subtitle mr-2"
                color="grey lighten-2"
              >
                <v-icon left color="green">stars</v-icon>
                Points
              </v-chip>
              <v-chip
                v-if="databucket.pointsRedeemable"
                class="capitalize table-subtitle mr-2"
                color="grey lighten-2"
              >
                <v-avatar left>
                  <v-icon color="green">shopping_cart_checkout</v-icon>
                </v-avatar>
                Redeemable
              </v-chip>
              <span class="page-title mr-6">{{ databucket.title }}</span>
            </v-row>
          </v-col>
        </v-row>
      </v-toolbar>

      <v-row no-gutters justify="start" class="mt-5">
        <v-col cols="12" lg="12">
          <v-tabs-items v-model="tab">
            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <preview />
            </v-tab-item>

            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <actuals-import-stepper />
            </v-tab-item>

            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <targets-import-stepper />
            </v-tab-item>

            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <points-formula />
            </v-tab-item>

            <v-tab-item
              transition="fade-transition"
              reverse-transition="fade-transition"
            >
              <settings />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>

    <v-row justify="center">
      <v-dialog v-model="isProcessing" persistent max-width="550px">
        <v-card height="400" class="pt-6">
          <v-card-text class="pt-14">
            <v-row justify="center" align="center">
              <v-progress-circular
                indeterminate
                color="primary"
                class="mb-6 mt-6"
                width="10"
                size="100"
              ></v-progress-circular>
            </v-row>
            <v-row justify="center">
              <div class="card-title mb-6 mt-6">Processing...</div>
            </v-row>
            <v-row
              no-gutters
              class="card-title mt-6 primary--text"
              justify="center"
            >
              Do Not Close Browser
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <databucket-sync-dialog
      v-if="openSyncDialog"
      :logs="syncLogs"
      :syncSuccess="syncSuccess"
    />

    <div v-if="!loadingDatabucket" class="sticky-footer">
      <v-btn
        :disabled="!changes.length || demo"
        :loading="isProcessing"
        color="primary"
        @click="handleSave"
        elevation="0"
      >
        Save Data Bucket</v-btn
      >
      <span class="incentable-form-subheading ml-3 mr-1">
        Last Saved {{ databucket.updated | date }}
      </span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { store } from "@/store";
import ActualsImportStepper from "./Components/Actuals/ImportStepper.vue";
import Preview from "./Components/Builder/Preview.vue";
import TargetsImportStepper from "./Components/Targets/ImportStepper.vue";
import Settings from "./Components/Settings/Settings.vue";
import PointsFormula from "./Components/Points/Formula.vue";

export default {
  beforeRouteEnter(to, from, next) {
    const { databucketId } = to.params;
    Promise.all([
      store.dispatch("loadingDatabucket", true),
      store.dispatch("loadDatabucket", databucketId),
      store.dispatch("setNavMenu", true),
      store.dispatch("loadCompanies"),
      store.dispatch("loadMembers"),
      store.dispatch("loadTablesByDatabucket", databucketId),
      store.dispatch("loadLeaderboardsByDatabucket", databucketId),
      store.dispatch("setNewCsvTargetFileName", ""),
      store.dispatch("setNewCsvActualFileName", ""),
    ])
      .then(() => {
        next();
      })
      .catch(() => {
        next("/404");
      });
  },
  components: {
    ActualsImportStepper,
    Preview,
    TargetsImportStepper,
    Settings,
    PointsFormula,
  },
  data: function () {
    return {
      tab: null,
      isProcessing: false,
      syncSuccess: false,
      openSyncDialog: false,
      syncLogs: [],
    };
  },
  created() {
    this.$store.dispatch("setBreadCrumbDetail", this.databucket.title);
    this.$store.dispatch("updateChanges", []);
  },
  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loadingDatabucket() {
      return this.$store.getters.loadingDatabucket;
    },
    databucket() {
      return this.$store.getters.databucket;
    },
    participants() {
      return this.$store.getters.databucketParticipants;
    },
    columnData() {
      return this.$store.getters.databucketColumnData;
    },
    totalData() {
      return this.$store.getters.databucketTotalData;
    },
    pointsFormula() {
      return this.$store.getters.databucketPointsFormula;
    },
    changes() {
      return this.$store.getters.changes;
    },
  },

  methods: {
    async handleSave() {
      this.isProcessing = true;
      this.syncSuccess = false;

      let columnDoc, totalDoc;
      try {
        ({ columnDoc, totalDoc } = await this.$store.dispatch("updateResult"));

        this.isProcessing = false;
      } catch (e) {
        return;
      }

      if (!this.changes.find((change) => change === "result")) {
        this.$store.dispatch("updateChanges", []);
        return;
      }

      this.$store.dispatch("updateChanges", []);
      this.openSyncDialog = true;

      this.syncLogs = [
        "Processing...",
        "Checking for Tables and Leaderboards...",
      ];

      await Promise.all([
        this.$store.dispatch("loadTablesByDatabucket", this.databucket.id),
        this.$store.dispatch(
          "loadLeaderboardsByDatabucket",
          this.databucket.id
        ),
      ]);

      const tables = this.$store.getters.tables;
      const leaderboards = this.$store.getters.leaderboards;

      this.syncLogs = [
        ...this.syncLogs,
        `${tables.length} tables and ${leaderboards.length} leaderboards found...`,
      ];

      for (let i = 0; i < tables.length; i += 1) {
        const table = tables[i];

        this.syncLogs = [
          ...this.syncLogs,
          `[Table] ${table.title} is processing...`,
        ];

        this.$store.dispatch("fakeLoadCurrentTable", {
          tableData: table,
          tableRawData: columnDoc,
        });

        await this.$store.dispatch("syncTable");

        this.syncLogs = [...this.syncLogs, `[Table] ${table.title} processed`];
      }

      for (let i = 0; i < leaderboards.length; i += 1) {
        const leaderboard = leaderboards[i];

        this.syncLogs = [
          ...this.syncLogs,
          `[Leaderboard] ${leaderboard.title} is processing...`,
        ];

        this.$store.dispatch("fakeLoadCurrentLeaderboard", {
          leaderboardData: leaderboard,
          leaderboardRawData: totalDoc,
        });

        await this.$store.dispatch("syncLeaderboard");

        this.syncLogs = [
          ...this.syncLogs,
          `[Leaderboard] ${leaderboard.title} processed`,
        ];
      }

      this.syncLogs = [...this.syncLogs, "Success!"];

      this.syncSuccess = true;
      this.$store.dispatch("setLastRuleDeleted", false);

      setTimeout(() => {
        this.openSyncDialog = false;
        this.syncLogs = [];
        this.$store.dispatch("setSnackbar", "Data Bucket processing complete");
      }, 3000);
    },
  },

  watch: {
    columnData(newVal, oldVal) {
      const equality = _.isEqual(newVal, oldVal);
      if (!equality) {
        const changes = [...this.changes, "result"];
        this.$store.dispatch("updateChanges", changes);
      }
    },
    totalData(newVal, oldVal) {
      const equality = _.isEqual(newVal, oldVal);
      if (!equality) {
        const changes = [...this.changes, "result"];
        this.$store.dispatch("updateChanges", changes);
      }
    },
    pointsFormula(newVal, oldVal) {
      const equality = _.isEqual(newVal, oldVal);
      if (!equality) {
        const changes = [...this.changes, "result"];
        console.log("watcher on db", changes);
        this.$store.dispatch("updateChanges", changes);
      }
    },
    databucket(newVal, oldVal) {
      if (
        newVal.title !== oldVal.title ||
        newVal.targetCodes !== oldVal.targetCodes ||
        newVal.displayTitle !== oldVal.displayTitle ||
        newVal.status !== oldVal.status ||
        newVal.pointsRedeemable !== oldVal.pointsRedeemable
      ) {
        const changes = [...this.changes, "meta"];
        this.$store.dispatch("updateChanges", changes);
      }

      if (!_.isEqual(newVal.previewColumns, oldVal.previewColumns)) {
        const changes = [...this.changes, "result"];
        this.$store.dispatch("updateChanges", changes);
      }
    },
  },
};
</script>

<style scoped>
.sticky-footer {
  position: fixed;
  background: white;
  bottom: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  z-index: 1 !important;
  padding: 10px;
  width: 100%;
}
.margin-bottom {
  margin-bottom: 100px;
}
</style>
